import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";

export const downloadInstructions = async () => {
  const sessionid = getSessionId();

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}web/content/2907/?download=true&o_ses_id=${sessionid}`,
      {
        responseType: "blob",
      }
    );

    const fileName = "Instructions.pdf";
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    // // console.error("Failed to download instructions:", error);
  }
};
