import React from "react";
import NavbarVTX from "../../../components/common/Navbar";
import Header from "../../../components/common/Header";
import Input from "../../../components/common/Input";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import Dropdown from "../../../components/common/dropdowns/Dropdown";
import CountryDropdown from "../../../components/common/CountryDropdown";
import DatePicker from "../../../components/common/DatePicker";
import Button from "../../../components/common/Button";
import CheckBoxes from "../../../components/common/CheckBoxes";
import { FormData } from "./Types/KeyIndividualType";
import "./KeyIndividual.css";
import { useNavigate, useParams } from "react-router-dom";
import IndividualDesignation from "../../../components/common/dropdowns/IndividualDesignation";
import Gender from "../../../components/common/dropdowns/Gender";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { CPKeyPersonDetailsType } from "../../opsUser/ppApplication/ppIndia/pages/types/KeyPersonDetailsType";
import SectionHeading from "../../../components/common/SectionHeading";
import { EMAIL_REGEX, PHONE_REGEX } from "../../constants/regexPatterns";
import { createKeyPersonAction } from "../../opsUser/ppApplication/ppIndia/redux/action/createKeyPersonAction";
import { CPCreateKeyPersonAction } from "../../opsUser/ppApplication/ppIndia/redux/action/CPCreateKeyPersonAction";
import PhoneInputWithDropdown from "../../../components/common/PhoneInput";
import { DisplayBox } from "../../../components/containers/DisplayBox";

const AddKeyIndividual: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const dispatch = useDispatch<AppDispatch>();
  const [showSpinner, setShowSpinner] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CPKeyPersonDetailsType>();
  const designation = watch("designation");
  const handleCPKeyPersonAdd: SubmitHandler<CPKeyPersonDetailsType> = (
    CPKeyPersonsDetails
  ) => {
    setShowSpinner(true);
    CPKeyPersonsDetails.customer_id = customerId;
    CPKeyPersonsDetails.receive_otp_by = "email";
    CPKeyPersonsDetails.key_person_type_ids = [212];
    // // console.log(CPKeyPersonsDetails);
    dispatch(CPCreateKeyPersonAction(CPKeyPersonsDetails)).then((response) => {
      navigate(`/key-person-details/${customerId}`);
      //  // console.log("response--", response);
      setShowSpinner(false);
    });
  };
  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
        <SectionHeading text="ADD KEY REPRESENTIVE" />

        <Form>
          <Row>
            <Col sm={6}>
              <Input
                label="Type"
                name="key_person_type_ids"
                register={register}
                defaultValue={"Key Person"}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <IndividualDesignation
                label="Designation"
                name="designation"
                register={register}
                required
                requiredMessage="designation is required"
                error={errors.designation?.message}
              />
            </Col>
          </Row>
          <DisplayBox display={designation === "others"}>
            <Row>
              <Col sm={6}>
                <Input
                  label={"If Others,"}
                  name={"if_others"}
                  register={register}
                  required={designation === "others"}
                  placeholder="Designation if others"
                  error={errors.if_others?.message}
                />
              </Col>
            </Row>
          </DisplayBox>
          <Row>
            <Col sm={6}>
              <Input
                label="First name"
                name="first_name"
                register={register}
                placeholder="Enter first name"
                required
                requiredMessage="first name is required"
                error={errors.first_name?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Input
                label="Middle name"
                name="middle_name"
                register={register}
                placeholder="Enter middle name"
                //required
                //requiredMessage="middle name is required"
                //error={errors.middle_name?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Input
                label="Last name"
                name="last_name"
                register={register}
                placeholder="Enter last name"
                required
                requiredMessage="last name is required"
                error={errors.last_name?.message}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col sm={6}>
              <Input
                label="Mother name"
                name="mother_name"
                register={register}
                placeholder="Enter mother name"
                required
                requiredMessage="mother name is required"
                error={errors.mother_name?.message}
              />
            </Col>
          </Row> */}
          <Row>
            <Col sm={6}>
              <Gender
                label="Gender"
                name="gender"
                register={register}
                // required
                // requiredMessage="gender is required"
                // error={errors.gender?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              {/* <Input
                label="Mobile/Phone"
                name="phone"
                register={register}
                required
                requiredMessage="Mobile/Phone is required"
                error={errors.phone?.message}
                regexPattern={PHONE_REGEX}
                placeholder="Enter Mobile"
                maxLength={10}
              /> */}
              <PhoneInputWithDropdown
                label="Contact No"
                name="phone"
                register={register}
                required
                isdCodeName="phone_country_code"
                isdCodeRegister={register}
                error={errors.phone?.message}
                regexPattern={PHONE_REGEX}
                placeholder="Enter Mobile "
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Input
                label="Email"
                name="email"
                register={register}
                placeholder="Enter Email"
                required
                requiredMessage="Email is required"
                error={errors.email?.message}
                regexPattern={EMAIL_REGEX}
              />
            </Col>
          </Row>
          <Row>
            <Col md={20} className="text-end">
              {showSpinner ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Button
                  label="Save & Next"
                  type="submit"
                  onClick={handleSubmit(handleCPKeyPersonAdd)}
                ></Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default AddKeyIndividual;
