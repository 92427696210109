import axios from "axios";
import { CreateIndAppType } from "../pages/types/CreateIndAppType";

// interface CreateAppData {
//   is_counterparty?: boolean;
//   referred_by?: boolean;
//   business_participant_type?: string;
//   business_registration_country_id?: number;
//   customer_contact_name?: string;
//   customer_contact_mobile_country_code?: string;
//   customer_contact_mobile?: string;
//   customer_contact_email?: string;
//   employee_declaration_accepted?: boolean;
//   employee_id?: string;
//   employee_name?: string;
//   submitted_date?: string;
//   business_gst_details_fetch_over_api?: boolean;
//   business_gst_api_results_available?: boolean;
//   business_gst?: string;
//   business_constitution_type?: string;
//   business_is_ftr_ftnr?: string;
//   business_ftnr_remarks?: string;
// }

export const createApplication = (
  customerDetails: CreateIndAppType,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/create/one/tradexwbench.customer`,
      {
        params: {
          context: {},
          data: customerDetails,
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
