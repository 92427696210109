import axios from "axios";

export const getAllDueDiligenceDoc = (
  customerId: number,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/customer.due_diligence_document`,
      {
        params: {
          filter: `[('customer_id', '=',  ${customerId})]`,
          query:
            "{id, customer_id, customer_due_diligence_document_type, customer_due_diligence_document_name, customer_due_diligence_attachment_ids, file_name, maker_due_diligence_status, maker_due_diligence_comments, maker_due_diligence_attachment_ids,checker_due_diligence_status, checker_due_diligence_comments, checker_due_diligence_attachment_ids}",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
