import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import {
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Modal,
  Image,
} from "react-bootstrap";
// import Button from "../../../../components/common/Button";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { useForm } from "react-hook-form";
import RadioButton from "../../../../../components/common/RadioButton";
import { MdOutlineCloudDownload } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { uploadAttachmentAction } from "../redux/action/UploadAttachmentAction";
import { updateAppAction } from "../redux/action/updateAppAction";
import Button from "../../../../../components/common/Button";
import axios from "axios";
import { getSessionId, getUserRole } from "../../../../../utils/tokenHandlers";
import { updateStageAction } from "../redux/action/updateStageAction";
import handleFileWithSizeLimit from "../../../../constants/fileSizeLimit";
import { uploadAttachment } from "../apis/uploadAttachment";
import { updateSignedApplication } from "../apis/updateSignedApplication";
import ReferredByDropdown from "../../../../../components/common/dropdowns/CPReferredBy";
import { fieldValidationAction } from "../redux/action/fieldValidation";
import KYCListView from "../../../../../components/common/KYCDocsList";
import { updateMasterAgreement } from "../apis/updateMasterAgreement";
import { getAppAction } from "../redux/action/getAppAction";
import { AuthoriseErrorModal } from "../components/AuthoriseErrorModal";
import { ValidationErrorModal } from "../components/ValidationErrorModal";
import { deleteDocument } from "../../../../guestUser/ppApplication/redux/action/deleteDocument";
import { deleteCustomerDocument } from "../apis/deleteCustomerDocuments";

interface FormSigningType {
  file: number[];
  sign_type: string;
}

interface updateCustomerType {
  signed_application_form_attachment_ids: number[];
}

const OpsApplicationSigningPage: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  let stage = `move_stage_form_signing_to_verification`;
  const dispatch = useDispatch<AppDispatch>();
  const fileInputRef1 = useRef<HTMLInputElement | null>(null);
  const fileInputRef2 = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<string | Blob>("");
  const [docUplaoded, setDocUploaded] = useState(false);
  const [docName, setDocName] = useState<string | null>(null);
  const [buttonText, setButtonText] = useState("Upload");
  const [showBtnSpinner, setShowBtnSpinner] = useState(false);
  const [sendBackSpinner, setShowSendBackSpinner] = useState(false);
  // const [validationError, setValidationError] = useState("");
  // const [showValidationModal, setShowValidationModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormSigningType>();
  const useRole = getUserRole();
  const sessionId = getSessionId();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showModalSpinner, setShowModalSpinner] = useState(false);
  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");
  const signApplicationRoleOptions = [
    { label: "Digitally Sign (Online)", value: "Digitally Sign (Online)" },
    { label: "Print & Sign (Offline)", value: "Print & Sign (Offline)" },
  ];
  const [validationError, setValidationError] = useState("");
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [authoriseError, setAuthoriseError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [showUploadSpinner, setShowUploadSpinner] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
  // const handleAddClick = () => {
  //   navigate("/guest/ApplicationiUndertaking"); // Replace with the actual path
  // };

  // const handleUploadClick = () => {
  //   // Perform upload logic here
  //   // console.log("Uploading:", selectedFile);
  // };
  // const handleFilePickerClick = () => {
  //   const fileInput = document.getElementById("fileInput");
  //   if (fileInput) {
  //     fileInput.click();
  //   }
  // };
  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const selectedFile = event.target.files?.[0]; // Get the first file in the array
  //   setSelectedFile(selectedFile || null); // Set selectedFile or null if undefined
  //   setUploadDisabled(!selectedFile); // Disable the button if no file is selected
  // };

  const handleDocumentDelete = async () => {
    if (appStage === "In Form Signing") {
      const docId = getAppSelector.data.master_agreeement_attachment_ids[0];
      // console.log(docId);
      if (docId) {
        const updateCustomerData = {
          master_agreeement_attachment_ids: [],
        };
        const deleteDocument = await deleteCustomerDocument(
          customerId,
          updateCustomerData
        ).then((resp) => {
          // console.log(resp);
          dispatch(getAppAction(customerId)).then((resp) => {
            alert("Master Agreement Deleted!");
          });
        });
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const handleDocumentDeleteSA = async () => {
    if (appStage === "In Form Signing") {
      const docId =
        getAppSelector.data.signed_application_form_attachment_ids[0];
      // console.log(docId);
      if (docId) {
        const updateCustomerData = {
          signed_application_form_attachment_ids: [],
        };
        const deleteDocument = await deleteCustomerDocument(
          customerId,
          updateCustomerData
        ).then((resp) => {
          // console.log(resp);
          dispatch(getAppAction(customerId)).then((resp) => {
            alert("Signed Application Deleted!");
            setUploadDisabled(false);
            setButtonText("Upload");
          });
        });
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const handleMasterAgreementClick = async () => {
    setShowUpload(true);
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  let appStage = getAppSelector.data.stage;
  const isCounterParty = getAppSelector.data.is_counterparty;
  const handleDownloadPdf = async () => {
    const sessionid = getSessionId();
    const appNumber = getAppSelector.data.application_uid;
    try {
      setShowSpinner(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}application/print/${customerId}?o_ses_id=${sessionid}`,
        {
          responseType: "blob",
        }
      );
      const fileName = `${appNumber}_ApplicationForm.pdf`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      // console.error("An error occured", error);
    }
  };

  const onSubmit = (data: any) => {
    // console.log(data);
    dispatch(
      uploadAttachmentAction({
        fileName: data.file[0].name,
        file: data.file[0],
      })
    ).then((response) => {
      // console.log(response);
      const updateCustomerData: updateCustomerType = {
        signed_application_form_attachment_ids: [
          response.payload.data.attachement,
        ],
      };
      dispatch(updateAppAction({ customerId, updateCustomerData }));
    });
  };

  const openValidationModal = (validationErr: string) => {
    setValidationError(validationErr);
    setShowValidationModal(true);
  };

  const UpdateStageAndNavigate = () => {
    if (appStage === "In Form Signing") {
      // dispatch(updateStageAction({ customerId, stage })).then((response: any) => {
      //   // console.log(response);
      //   navigate(`/ApplicationSubmission/${customerId}`);
      // });
      setShowBtnSpinner(true);

      dispatch(getAppAction(customerId)).then((resp) => {
        // console.log(
        //   resp.payload.result.signed_application_form_attachment_ids.length,
        //   "here"
        // );
        if (
          resp.payload.result.signed_application_form_attachment_ids.length ===
          0
        ) {
          alert("Please upload Signed Application to continue!");
          setShowBtnSpinner(false);
        } else {
          dispatch(fieldValidationAction({ customerId, stage })).then(
            (response) => {
              // console.log("Api resp-->", response);
              if (response.payload.error) {
                setShowBtnSpinner(false);
                const validationErr = response.payload.error.data.message;
                // console.warn(validationErr);
                openValidationModal(validationErr);
              } else {
                setShowBtnSpinner(false);
                navigate(`/ApplicationSubmission/${customerId}`);
              }
            }
          );
        }
      });
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const sendBacktoFormFilling = () => {
    setShowSendBackSpinner(true);
    let stage = "New / Draft";
    dispatch(updateStageAction({ customerId, stage })).then((response: any) => {
      // console.log(response);
      if (useRole === "maker") {
        navigate("/ops/Dashboard");
      } else {
        navigate("/");
      }
      setShowSendBackSpinner(false);
    });
  };

  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];

      // Check if the file type is allowed
      const allowedTypes = ["pdf", "jpeg", "png", "jpg"];
      const fileType = selectedFile.name.split(".").pop()?.toLowerCase();

      if (!fileType || !allowedTypes.includes(fileType)) {
        // Display an alert or take appropriate action
        alert(
          "Unsupported file type. Please select a file with one of the following types: pdf, jpeg, png, jpg"
        );
        return;
      }

      setImage(selectedFile);
      localStorage.setItem("fileName", selectedFile.name);
      setDocName(selectedFile.name);
      setUploadDisabled(false);
      setButtonText("Upload");
    } else {
      // console.warn("No files selected.");
    }
  };
  const handleAPI = async () => {
    const files2 = fileInputRef2.current?.files;
    if (!files2 || files2.length === 0) {
      alert("Please select a file before uploading.");
      return;
    }

    try {
      localStorage.setItem("customerId", `${customerId}`);
      if (docName && image) {
        const sessionId = getSessionId();
        // setUploading(true);
        const response = await uploadAttachment(docName, image, sessionId);
        // console.log("API response::", response);
        if (response.data.message === "Success") {
          const docId = response.data.data.attachement;
          // console.log("docId-->", docId);

          if (docId) {
            const signedAppUpload = await updateSignedApplication(
              customerId,
              docId
            );
            if (signedAppUpload.data.result == true) {
              setUploadDisabled(true);
              // setUploading(false); // Set uploading state back to false
              setButtonText("Document Uploaded!");
              dispatch(getAppAction(customerId));
            } else {
              alert("Something went wrong, Please contact Admin");
            }
          }
          // fetchDocsDatas();
          // localStorage.clear();
          setDocUploaded(true);

          if (fileInputRef2.current) {
            fileInputRef2.current.value = "";
            // setButtonText("Upload");
          }

          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        } else if (response.type === "uploadAttachment/rejected") {
          // navigate("/");
        }
      }
    } catch (error) {
      // console.error("An error occurred--:", error);
    }
  };
  const handleAPIMA = async () => {
    const files1 = fileInputRef1.current?.files;
    if (!files1 || files1.length === 0) {
      alert("Please select a file before uploading.");
      return;
    }
    if (appStage === "In Form Signing") {
      try {
        localStorage.setItem("customerId", `${customerId}`);
        if (docName && image) {
          const sessionId = getSessionId();
          // setUploading(true);
          const response = await uploadAttachment(docName, image, sessionId);
          // console.log("API response::", response);
          if (response.data.message === "Success") {
            const docId = response.data.data.attachement;
            // console.log("docId-->", docId);

            if (docId) {
              const signedAppUpload = await updateMasterAgreement(
                customerId,
                docId
              );
              // console.log(signedAppUpload, "upload");
              if (signedAppUpload.data.result == true) {
                setFileUploaded(true);
                setShowUpload(false);
                dispatch(getAppAction(customerId));
              } else {
                setFileUploaded(false);
                alert("Something went wrong, Please contact Admin");
              }
            }
            // fetchDocsDatas();
            // localStorage.clear();
            setDocUploaded(true);

            if (fileInputRef1.current) {
              fileInputRef1.current.value = "";
              // setButtonText("Upload");
            }

            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          } else if (response.type === "uploadAttachment/rejected") {
            // navigate("/");
          }
        }
      } catch (error) {
        // console.error("An error occurred--:", error);
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const handleDocumentViewSA = async () => {
    setImageData("");
    const docId = getAppSelector.data.signed_application_form_attachment_ids[0];
    // console.log(docId);
    const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?o_ses_id=${sessionId}`;
    try {
      if (docId) {
        handleShow();
      } else {
        alert("Signed Application is not yet Uploaded!");
      }
      setShowModalSpinner(true);
      const response = await axios.get<ArrayBuffer>(apiUrl, {
        responseType: "arraybuffer",
      });

      const dataAsArray = new Uint8Array(response.data);

      if (
        dataAsArray[0] === 0x25 &&
        dataAsArray[1] === 0x50 &&
        dataAsArray[2] === 0x44 &&
        dataAsArray[3] === 0x46
      ) {
        // const pdfBase64 = `data:application/pdf;base64,${btoa(
        //   String.fromCharCode.apply(null, Array.from(dataAsArray))
        // )}`;
        setImageData(apiUrl);
        // console.warn("its a pdf");
        setFileType("pdf");
      } else {
        setShowModalSpinner(true);
        const imageBase64 = `data:image/png;base64,${btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
        setImageData(imageBase64);
        // console.warn("its a img");
        setFileType("img");
      }

      // setShowSpinner(false);
      setShowModalSpinner(false);
    } catch (error) {
      // console.error("Error fetching file:", error);
      setShowModalSpinner(false);
    }
    // console.log("API URL:", apiUrl);
  };

  const handleDocumentViewMA = async () => {
    setImageData("");
    const docId = getAppSelector.data.master_agreeement_attachment_ids[0];
    // console.log(docId);
    const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?o_ses_id=${sessionId}`;
    try {
      if (docId) {
        handleShow();
      } else {
        alert("Master Agreement is not yet Uploaded!");
      }

      setShowModalSpinner(true);
      const response = await axios.get<ArrayBuffer>(apiUrl, {
        responseType: "arraybuffer",
      });

      const dataAsArray = new Uint8Array(response.data);

      if (
        dataAsArray[0] === 0x25 &&
        dataAsArray[1] === 0x50 &&
        dataAsArray[2] === 0x44 &&
        dataAsArray[3] === 0x46
      ) {
        // const pdfBase64 = `data:application/pdf;base64,${btoa(
        //   String.fromCharCode.apply(null, Array.from(dataAsArray))
        // )}`;
        setImageData(apiUrl);
        // console.warn("its a pdf");
        setFileType("pdf");
      } else {
        setShowModalSpinner(true);
        const imageBase64 = `data:image/png;base64,${btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
        setImageData(imageBase64);
        // console.warn("its a img");
        setFileType("img");
      }

      // setShowSpinner(false);
      setShowModalSpinner(false);
    } catch (error) {
      // console.error("Error fetching file:", error);
      setShowModalSpinner(false);
    }
    // console.log("API URL:", apiUrl);
  };

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [customerId, dispatch, fileUploaded]);

  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, document.title, window.location.href);
      });
    };

    disableBackButton();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, []);

  // const openValidationModal = (validationErr: string) => {
  //   setValidationError(validationErr);
  //   setShowValidationModal(true);
  // };

  const openModal = (errMsg: string) => {
    setAuthoriseError(errMsg);
    setShowModal(true);
  };
  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
        <Row>
          <Col
            md={12}
            className="text-center mt-4"
            style={{ color: "#214382" }}
          >
            <SectionHeading text="SIGN MASTER AGREEMENT & APPLICATION FORM" />
          </Col>
        </Row>
        <Row className="text-center" style={{ marginLeft: "42%" }}>
          <Col md={3}>
            {/* <RadioButton
              label=""
              name="business_participant_type"
              options={signApplicationRoleOptions}
              register={register}
              required
              defaultValue="Print & Sign (Offline)"
            /> */}
            <Form.Check
              className="form-label"
              inline
              label={"Print & Sign (Offline)"}
              type="radio"
              value={"print and sign"}
              {...register("sign_type", {
                required: "Required",
              })}
              defaultChecked={true}
            />
          </Col>
        </Row>
        {isCounterParty === false && (
          <Row className="text-center">
            <Row md={12} style={{ marginLeft: "29%" }}>
              <Col md={5}>
                <KYCListView
                  items={[
                    {
                      label: "Master Agreement",
                      required: false,
                      onCancelClick: () => {
                        handleDocumentDelete();
                      },
                      onViewClick: () => {
                        // handleShow();
                        handleDocumentViewMA();
                      },
                      onClick: () => {
                        handleMasterAgreementClick();
                      },
                      style: {
                        backgroundColor:
                          getAppSelector.data &&
                          getAppSelector.data
                            .master_agreeement_attachment_ids &&
                          getAppSelector.data.master_agreeement_attachment_ids
                            .length !== 0
                            ? "#9ef89e"
                            : "",
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row className="mt-3" md={12} style={{ marginLeft: "29%" }}>
              <Col md={6}>
                {showUpload && (
                  <>
                    <Row md={12}>
                      <Col md={9}>
                        <Form.Group
                          controlId="formFileMultiple"
                          className="mb-3"
                        >
                          <Form.Control
                            type="file"
                            multiple
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleFileWithSizeLimit(e, undefined, handleImage)
                            }
                            ref={fileInputRef1}
                            accept="pdf, jpeg, png, jpg"
                            size="sm"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        {showUploadSpinner ? (
                          <Spinner
                            animation="border"
                            role="status"
                            variant="primary"
                          ></Spinner>
                        ) : (
                          <Button
                            label="Upload"
                            type="button"
                            onClick={handleAPIMA}
                            // disabled={uploadDisabled}
                            className="btn-sm"
                            disabled={appStage !== "In Form Signing"}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="text-center"></Row>
                  </>
                )}
              </Col>
            </Row>
          </Row>
        )}

        <Col md={12} className="text-center mt-4" style={{ color: "#214382" }}>
          <SectionHeading text="Download pre-filled application form with KYC and other documents" />
        </Col>
        <Col md={12} className="text-center mt-4" style={{ color: "#214382" }}>
          {/* <a href="https://workbench.uat.vayanatradexchange.com/application/print/329" download> */}
          {showSpinner ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <MdOutlineCloudDownload
              onClick={handleDownloadPdf}
              style={{ width: "100px", height: "60px", cursor: "pointer" }}
            ></MdOutlineCloudDownload>
          )}
        </Col>
        <Col md={12} className="text-center mt-4" style={{ color: "#214382" }}>
          <SectionHeading text="Upload Signed Copy of the Application Form" />
        </Col>
        {/* <Row className="text-center"> */}
        {/* <Row md={12} style={{marginLeft:"29%"}}>
              <Col md={5}>
                <KYCListView
                  items={[
                    {
                      label: "Master Agreement",
                      required: false,
                      onCancelClick: () => {},
                      onViewClick: () => {
                        handleShow();
                        handleDocumentViewMA();
                      },
                      onClick: () => {
                        handleMasterAgreementClick();
                      },
                      style: {
                        backgroundColor:
                          getAppSelector.data &&
                          getAppSelector.data
                            .master_agreeement_attachment_ids &&
                          getAppSelector.data.master_agreeement_attachment_ids
                            .length !== 0
                            ? "#9ef89e"
                            : "",
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row className="mt-3" md={12} style={{marginLeft:"29%"}}>
              <Col md={6}>
                {showUpload && (
                  <>
                    <Row md={12}>
                      <Col md={9}>
                        <Form.Group
                          controlId="formFileMultiple"
                          className="mb-3"
                        >
                          <Form.Control
                            type="file"
                            multiple
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleFileWithSizeLimit(e, undefined, handleImage)
                            }
                            ref={fileInputRef}
                            accept="pdf, jpeg, png, jpg"
                            size="sm"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        {showUploadSpinner ? (
                          <Spinner
                            animation="border"
                            role="status"
                            variant="primary"
                          ></Spinner>
                        ) : (
                          <Button
                            label="Upload"
                            type="button"
                            onClick={handleAPIMA}
                            // disabled={uploadDisabled}
                            className="btn-sm"
                          />
                        )}
                      </Col>
                    </Row>
                    <Row className="text-center"></Row>
                  </>
                )}
              </Col>
            </Row> */}

        <Row className="text-center">
          <Col md={6} className="mt-2 mb-2" style={{ margin: "auto" }}>
            <Col md={12}>
              <KYCListView
                items={[
                  {
                    label: "Signed Application",
                    required: true,
                    onCancelClick: () => {
                      handleDocumentDeleteSA();
                    },
                    onViewClick: () => {
                      // handleShow();
                      handleDocumentViewSA();
                    },
                    onClick: () => {
                      // handleMasterAgreementClick();
                    },
                    style: {
                      backgroundColor:
                        getAppSelector.data &&
                        getAppSelector.data
                          .signed_application_form_attachment_ids &&
                        getAppSelector.data
                          .signed_application_form_attachment_ids.length > 0
                          ? "#9ef89e"
                          : "",
                    },
                  },
                ]}
              />
            </Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="formFile">
                <Form.Label>File Upload</Form.Label>
                <Form.Control
                  type="file"
                  // required
                  {...register("file")}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleFileWithSizeLimit(e, undefined, handleImage)
                  }
                  ref={fileInputRef2}
                  accept="pdf, jpeg, png, jpg"
                />
              </Form.Group>
              <Row className="text-center">
                <Col md={12}>
                  <Button
                    label={buttonText}
                    type="button"
                    onClick={handleAPI}
                    disabled={uploadDisabled || appStage !== "In Form Signing"}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="text-start">
            {sendBackSpinner ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <Button
                label="Send back to Form Filling"
                onClick={() => sendBacktoFormFilling()}
                variant="outline-primary"
              />
            )}
          </Col>
          <Col md={6} className="text-end">
            {showBtnSpinner ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <Button
                label="Save & Next"
                onClick={() => UpdateStageAndNavigate()}
              />
            )}
          </Col>
        </Row>
      </Container>
      <Row>
        <ValidationErrorModal
          showValidationModal={showValidationModal}
          onHide={() => setShowValidationModal(false)}
          error={validationError}
        />
      </Row>
      <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Document View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="text-center">
              {showModalSpinner ? (
                <Spinner variant="primary" animation="border" />
              ) : //
              fileType === "pdf" ? (
                <iframe
                  src={imageData}
                  title="Document viewer"
                  style={{ width: "400px", height: "300px" }}
                />
              ) : (
                <Image
                  src={imageData}
                  style={{ width: "600px", height: "400px" }}
                  alt="Document not yet Uploaded"
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OpsApplicationSigningPage;
