import React, { ChangeEvent, useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Modal,
  Image,
} from "react-bootstrap";
import Button from "../../../../../components/common/Button";
import KYCListView from "../../../../../components/common/KYCDocsList";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { uploadAttachment } from "../../redux/action/uploadAttachment";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUploadedDocs } from "../../redux/action/fetchUploadedDocs";
import axios from "axios";
import { getSessionId } from "../../../../../utils/tokenHandlers";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { deleteDocument } from "../../redux/action/deleteDocument";
import KYCInstructionsBtn from "../../../../../components/common/KYCInstructionsBtn";
import handleFileWithSizeLimit from "../../../../constants/fileSizeLimit";
import { getAppAction } from "../../../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import { fetchEPDDropdown } from "../../../../apis/listOfValues/getKYCDropdownOptions";
import { fetchPOIDropdown } from "../../../../apis/listOfValues/getPOIDropdownOptions";
import { fetchPOADropdown } from "../../../../apis/listOfValues/getPOADropdownOptions";

const EntityLLPKYC: React.FC = () => {
  const { appId } = useParams();
  const customerId = Number(appId);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  let stage = state.data.stage;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [image, setImage] = useState<string | Blob>("");

  const [showSpinner, setShowSpinner] = useState(false);
  const [docUplaoded, setDocUploaded] = useState(false);
  const [docName, setDocName] = useState<string | null>(null);

  const [selectedDocument, setSelectedDocument] = useState("");
  const [selectedPOIItem, setselectedPOIItem] = useState<string | null>(null);
  const [selectedPOAItem, setselectedPOAItem] = useState<string | null>(null);
  const [selectedEPDItem, setselectedEPDItem] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Track selected file
  const [uploadDisabled, setUploadDisabled] = useState(true); // Initial disabled state

  const [uploading, setUploading] = useState(false);
  const [chkNavigate, SetChkNavigate] = useState(false);
  const [buttonText, setButtonText] = useState("Upload");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [selectedType, setSelectedType] = useState("");
  const [docNameType, setDocNameType] = useState("");
  const [isFunctionRunning, setIsFunctionRunning] = useState(false);

  const handleDocumentSelect = (documentName: string) => {
    setSelectedDocument(documentName);
    setUploading(false);
    setButtonText("Upload");
    localStorage.removeItem("docName");

    // // console.warn(documentName);
  };

  interface DropdownOption {
    label: string;
    value: string;
  }

  interface POIDropdownOptions {
    label: string;
    value: string;
  }

  interface POADropdownOptions {
    label: string;
    value: string;
  }

  const [epdDropdownOptions, setDropdownOptions1] = useState<DropdownOption[]>(
    []
  );
  const [poiDropdownOptions, setPOIDropdownOptions1] = useState<
    POIDropdownOptions[]
  >([]);
  const [poaDropdownOptions, setPOADropdownOptions1] = useState<
    POADropdownOptions[]
  >([]);

  useEffect(() => {
    // Fetch dropdown options from API
    fetchEPDDropdown()
      .then((options) => setDropdownOptions1(options))
      .then((options) => {
        // console.warn(options);
      });

    fetchPOIDropdown()
      .then((options) => setPOIDropdownOptions1(options))
      .then((options) => {
        // console.warn(options);
      });

    fetchPOADropdown()
      .then((options) => setPOADropdownOptions1(options))
      .then((options) => {
        // console.warn(options);
      });
    // fetchDropdownOptions2().then(options => setDropdownOptions2(options));
  }, []);

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  const handleDocType = (selectedItem: string) => {
    setSelectedType(selectedItem);
    // // console.warn(selectedItem);
    // // console.warn(selectedType);
    const documentType = selectedItem;
    // console.warn(documentType);
    localStorage.setItem("docType", selectedItem);
    // // console.error(localStorage.getItem("docType"));
  };
  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];

      // Check if the file type is allowed
      const allowedTypes = ["pdf", "jpeg", "png", "jpg"];
      const fileType = selectedFile.name.split(".").pop()?.toLowerCase();

      if (!fileType || !allowedTypes.includes(fileType)) {
        // Display an alert or take appropriate action
        alert(
          "Unsupported file type. Please select a file with one of the following types: pdf, jpeg, png, jpg"
        );
        return;
      }

      setImage(selectedFile);
      localStorage.setItem("fileName", selectedFile.name);
      setDocName(selectedFile.name);
      setUploadDisabled(false);
      setButtonText("Upload");
    } else {
      // console.warn("No files selected.");
    }
  };
  const handleAPI = async () => {
    if (!selectedDocument) {
      alert("Please select Document from the List");
    } else {
      try {
        setIsFunctionRunning(true);
        localStorage.setItem("customerId", `${customerId}`);
        if (docName && image) {
          setUploading(true);
          const response = await dispatch(uploadAttachment({ docName, image }));
          // console.log("API response::", response);
          if (response.type === "uploadAttachment/rejected") {
            alert("Something went wrong. Please try again");
            setIsFunctionRunning(false);
          }
          setUploadDisabled(true);
          setUploading(false); // Set uploading state back to false
          setButtonText("Document Uploaded!");
          // fetchDocsDatas();
          // localStorage.clear();
          setDocUploaded(true);

          if (fileInputRef.current) {
            fileInputRef.current.value = "";
            // setButtonText("Upload");
          }
          setIsFunctionRunning(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (error) {
        // console.error("An error occurred--:", error);
        setIsFunctionRunning(false);
      }
    }
  };

  const handlePOIDropdownSelect = (option: {
    label: string;
    value: string;
  }) => {
    setselectedPOIItem(option.value);
    setSelectedDocument(`Proof of Identity - ${option.label}`);
    // // console.warn(item);
    localStorage.setItem("docName", option.value);
  };

  const handlePOADropdownSelect = (option: {
    label: string;
    value: string;
  }) => {
    setselectedPOAItem(option.value);
    setSelectedDocument(`Proof of Address - ${option.label}`);
    localStorage.setItem("docName", option.value);
  };

  const handleEPDDropdownSelect = (option: {
    label: string;
    value: string;
  }) => {
    setselectedEPDItem(option.value);
    setSelectedDocument(`Entity Proof Document - ${option.label}`);
    localStorage.setItem("docName", option.value);
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]; // Get the first file in the array
    setSelectedFile(selectedFile || null); // Set selectedFile or null if undefined
    // setUploadDisabled(!selectedFile); // Disable the button if no file is selected
    setUploadDisabled(selectedFile === null);
  };
  const POIdropdownItems = ["PAN", "Others"];
  const POAdropdownItems = [
    "GST Certificate",
    "Import Export Licence Certificate",
    "shopsEstablishment",
    "Certificate of Incorporation",
    "Others",
  ];
  const EPDdropdownItems = [
    "GST Certificate",
    "tradeLicense",
    "shopsEstablishment",
    "regCertificateStateGovtStatutoryBody",
    "Others",
  ];
  const [docObjects, setDocObjects] = useState<
    { doc_type: string; doc_id: number; doc_name: string }[]
  >([]);

  useEffect(() => {
    const fetchDocsData = async () => {
      try {
        const result = await dispatch(fetchUploadedDocs(customerId));
        if (result.payload) {
          // console.warn("success");
          // console.warn(result.payload);
          const documents = result.payload.result.result;
          const docObjects = documents.map(
            (document: {
              document_type: any;
              attachment_ids: any[];
              document_name: any;
            }) => ({
              doc_type: document.document_type,
              doc_id: document.attachment_ids[0],
              doc_name: document.document_name,
            })
          );
          setDocObjects(docObjects);
          // console.warn("Docs Object", docObjects);
        }
      } catch (error) {
        // console.error("An error occurred", error);
      }
    };
    fetchDocsData();
  }, [customerId, dispatch, docUplaoded]);

  const sessionId = getSessionId();
  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");
  // const dataAsArray = new Uint8Array(response.data);

  const fetchDocContent = async (docType: string) => {
    setShowSpinner(true);
    setOtherFiles([]);
    if (docType === "OT") {
      const otherDocObjects = docObjects.filter((doc) => doc.doc_type === "OT");
      const promises = [];

      for (const otherDocObject of otherDocObjects) {
        const docId = otherDocObject.doc_id;
        setDocNameType(otherDocObject.doc_name);
        const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}?o_ses_id=${sessionId}`;

        const promise = axios
          .get<ArrayBuffer>(apiUrl, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            const dataAsArray = new Uint8Array(response.data);
            // console.log(apiUrl);

            if (
              dataAsArray[0] === 0x25 &&
              dataAsArray[1] === 0x50 &&
              dataAsArray[2] === 0x44 &&
              dataAsArray[3] === 0x46
            ) {
              // It's a PDF
              setFileType("pdf");
              setOtherFiles((prevFiles) => [
                ...prevFiles,
                { url: apiUrl, type: "pdf" },
              ]);
            } else {
              const imageBase64 = `data:image/png;base64,${btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              )}`;
              setImageData(imageBase64);
              // console.warn("its an img");
              setFileType("img");
              setOtherFiles((prevFiles) => [
                ...prevFiles,
                { url: apiUrl, type: "img" },
              ]);

              setFileType("img");
            }
          })
          .catch((error) => {
            // console.error("Error fetching file:", error);
          });

        promises.push(promise);
      }

      try {
        await Promise.all(promises);
      } catch (error) {
        // console.error("Error fetching files:", error);
      }

      setShowSpinner(false);
      // console.log("Other Document Objects:", otherDocObjects);
    } else {
      const docObject = docObjects.find((doc) => doc.doc_type === docType);
      if (docObject) {
        const docId = docObject.doc_id;
        setDocNameType(docObject.doc_name);
        const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?o_ses_id=${sessionId}`;

        try {
          const response = await axios.get<ArrayBuffer>(apiUrl, {
            responseType: "arraybuffer",
          });

          const dataAsArray = new Uint8Array(response.data);

          if (
            dataAsArray[0] === 0x25 &&
            dataAsArray[1] === 0x50 &&
            dataAsArray[2] === 0x44 &&
            dataAsArray[3] === 0x46
          ) {
            // const pdfBase64 = `data:application/pdf;base64,${btoa(
            //   String.fromCharCode.apply(null, Array.from(dataAsArray))
            // )}`;
            setImageData(apiUrl);
            // console.warn("its a pdf");
            setFileType("pdf");
          } else {
            const imageBase64 = `data:image/png;base64,${btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )}`;
            setImageData(imageBase64);
            // console.warn("its a img");
            setFileType("img");
          }

          setShowSpinner(false);
        } catch (error) {
          // console.error("Error fetching file:", error);
        }

        // console.log("API URL:", apiUrl);
      }
    }
  };

  const handleDocumentDelete = async (docType: string) => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      const docObject = docObjects.find((doc) => doc.doc_type === docType);
      if (docObject) {
        const docId = docObject.doc_id;
        // console.warn(" docid -->", docId);
        try {
          const result = await dispatch(deleteDocument(docId));
          if (result.payload) {
            // console.warn(result);
            window.location.reload();
            // console.warn("Document deleted");
          } else {
            // console.warn("error");
          }
        } catch (error) {
          // console.warn("Something went wrong", error);
        }
      } else {
        // console.warn("else block");
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const checkNavigate = async () => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      SetChkNavigate(true);
      // console.warn(docObjects);
      const docTypesArray = docObjects.map((docObject) => docObject.doc_type);
      if (
        docTypesArray.includes("POI") &&
        docTypesArray.includes("POA") &&
        docTypesArray.includes("EPD") &&
        docTypesArray.includes("PD") &&
        docTypesArray.includes("CC") &&
        docTypesArray.includes("IEC") &&
        docTypesArray.includes("AFS") &&
        docTypesArray.includes("BR") &&
        docTypesArray.includes("FEMA") &&
        docTypesArray.includes("BOD")
      ) {
        // console.warn("success");
        SetChkNavigate(false);
        navigate(`/keyperson/kyc-details/${customerId}`);
      } else {
        // console.warn("failure");
        SetChkNavigate(false);
        alert("Please upload all Mandatory Documents!");
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const [viewOtherModalShow, setViewOtherModalShow] = useState(false);

  const handleViewOtherModalClose = () => {
    setViewOtherModalShow(false);
  };
  const [otherFiles, setOtherFiles] = useState<
    Array<{ url: string; type: string }>
  >([]);

  const handleViewModalShow = (documentType: string) => {
    if (documentType === "OT") {
      // Fetch the document content for "Others" type
      fetchDocContent("OT");

      // Show the "Others" modal
      setViewOtherModalShow(true);
    }
  };

  const defaultDocName = docObjects.some((doc) => doc.doc_type === "EPD")
    ? docObjects.find((doc) => doc.doc_type === "EPD")?.doc_name
    : "";

  // Find the corresponding dropdown option for the default doc name
  const defaultDropdownOption = epdDropdownOptions.find(
    (option) => option.value === defaultDocName
  );

  const defaultPOADocName = docObjects.some((doc) => doc.doc_type === "POA")
    ? docObjects.find((doc) => doc.doc_type === "POA")?.doc_name
    : "";

  // Find the corresponding dropdown option for the default doc name
  const defaultPOADropdownOption = poaDropdownOptions.find(
    (option) => option.value === defaultPOADocName
  );

  const defaultPOIDocName = docObjects.some((doc) => doc.doc_type === "POI")
    ? docObjects.find((doc) => doc.doc_type === "POI")?.doc_name
    : "";

  // Find the corresponding dropdown option for the default doc name
  const defaultPOIDropdownOption = poiDropdownOptions.find(
    (option) => option.value === defaultPOIDocName
  );

  // Use the value of the default dropdown option, or null if not found
  const defaultDropdownValue = defaultDropdownOption
    ? { label: defaultDropdownOption.label, value: defaultDropdownOption.value }
    : null;

  const disableClicks = isFunctionRunning ? { pointerEvents: "none" } : {};
  return (
    <>
      {isFunctionRunning && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)", // semi-transparent black overlay
            zIndex: 9999,
          }}
        />
      )}
      <Container>
        <Row>
          <Row md={12} className="align-items-center">
            <Col md={10} className="text-start">
              <SectionHeading text="KYC & OTHER DOCUMENTS" />
            </Col>
            <Col md={2} className="text-end">
              <KYCInstructionsBtn />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <KYCListView
                items={[
                  {
                    label: "Authorisation Document",
                    required: true,

                    onCancelClick: () => {
                      handleDocumentDelete("BR");
                    },
                    onClick: () => {
                      // handleDocumentSelect("BR");
                      // handleDocType("BR");
                      if (!docObjects.some((doc) => doc.doc_type === "BR")) {
                        handleDocumentSelect("BR");
                        handleDocType("BR");
                      }
                    },
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "BR")) {
                        handleShow();
                        fetchDocContent("BR");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "BR"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },
                  {
                    label: `Proof of Identity${
                      docObjects.some((doc) => doc.doc_type === "POI")
                        ? `: ${defaultPOIDropdownOption?.label}`
                        : ""
                    }`,
                    required: true,
                    additionalDropdown: {
                      options: poiDropdownOptions,
                      onDropdownSelect: handlePOIDropdownSelect,
                      disabled: docObjects.some(
                        (doc) => doc.doc_type === "POI"
                      ),
                    },
                    onCancelClick: () => {
                      handleDocumentDelete("POI");
                    },
                    onClick: () => handleDocType("POI"),
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "POI")) {
                        handleShow();
                        fetchDocContent("POI");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "POI"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },
                  {
                    label: `Proof of Address${
                      docObjects.some((doc) => doc.doc_type === "POA")
                        ? `: ${defaultPOADropdownOption?.label}`
                        : ""
                    }`,
                    required: true,
                    additionalDropdown: {
                      options: poaDropdownOptions,
                      onDropdownSelect: handlePOADropdownSelect,
                      disabled: docObjects.some(
                        (doc) => doc.doc_type === "POA"
                      ),
                    },
                    onCancelClick: () => {
                      handleDocumentDelete("POA");
                    },
                    onClick: () => handleDocType("POA"),
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "POA")) {
                        handleShow();
                        fetchDocContent("POA");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "POA"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },

                  {
                    label: `Entity Proof Doc${
                      docObjects.some((doc) => doc.doc_type === "EPD")
                        ? `: ${defaultDropdownOption?.label}`
                        : ""
                    }`,
                    required: true,
                    additionalDropdown: {
                      options: epdDropdownOptions,
                      selectedOption: defaultDropdownOption,
                      onDropdownSelect: handleEPDDropdownSelect,
                      // disabled: docObjects.some(
                      //   (doc) => doc.doc_type === "EPD"
                      // ),
                      defaultValue: defaultDropdownValue,
                    },
                    onCancelClick: () => {
                      handleDocumentDelete("EPD");
                    },
                    onClick: () => handleDocType("EPD"),
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "EPD")) {
                        handleShow();
                        fetchDocContent("EPD");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "EPD"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },
                  // {
                  //   label: "Certificate of Incorporation",

                  //   onCancelClick: () => {
                  //     handleDocumentDelete("COI");
                  //   },
                  //   onClick: () => {
                  //     handleDocumentSelect("Certificate of Incorporation");
                  //     handleDocType("COI");
                  //   },
                  //   // variant: "success",
                  //   onViewClick: () => {
                  //     if (docObjects.some((doc) => doc.doc_type === "COI")) {
                  //       handleShow();
                  //       fetchDocContent("COI");
                  //     }
                  //   },
                  //   style: {
                  //     backgroundColor: docObjects.some(
                  //       (doc) => doc.doc_type === "COI"
                  //     )
                  //       ? "#9ef89e"
                  //       : "",
                  //   },
                  // },
                  {
                    label: "Import Export Certificate",
                    required: true,

                    onCancelClick: () => {
                      handleDocumentDelete("IEC");
                    },
                    onClick: () => {
                      // handleDocumentSelect("Import Export Certificate");
                      // handleDocType("IEC");
                      if (!docObjects.some((doc) => doc.doc_type === "IEC")) {
                        handleDocumentSelect("Import Export Certificate");
                        handleDocType("IEC");
                      }
                    },
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "IEC")) {
                        handleShow();
                        fetchDocContent("IEC");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "IEC"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },
                  {
                    label: "Deed of Partnership",
                    required: true,

                    onCancelClick: () => {
                      handleDocumentDelete("PD");
                    },
                    onClick: () => {
                      // handleDocumentSelect("Deed of Partnership");
                      // handleDocType("PD");
                      if (!docObjects.some((doc) => doc.doc_type === "PD")) {
                        handleDocumentSelect("Deed of Partnership");
                        handleDocType("PD");
                      }
                    },
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "PD")) {
                        handleShow();
                        fetchDocContent("PD");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "PD"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },
                  {
                    label: "Beneficial ownership declaration",
                    required: true,
                    onCancelClick: () => {
                      handleDocumentDelete("BOD");
                    },
                    onClick: () => {
                      // handleDocumentSelect("Beneficial ownership declaration");
                      // handleDocType("BOD");
                      if (!docObjects.some((doc) => doc.doc_type === "BOD")) {
                        handleDocumentSelect(
                          "Beneficial ownership declaration"
                        );
                        handleDocType("BOD");
                      }
                    },
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "BOD")) {
                        handleShow();
                        fetchDocContent("BOD");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "BOD"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },
                  {
                    label: "FEMA Declaration",
                    required: true,

                    onCancelClick: () => {
                      handleDocumentDelete("FEMA");
                    },
                    onClick: () => {
                      // handleDocumentSelect("FEMA Declaration");
                      // handleDocType("FEMA");
                      if (!docObjects.some((doc) => doc.doc_type === "FEMA")) {
                        handleDocumentSelect("FEMA Declaration");
                        handleDocType("FEMA");
                      }
                    },
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "FEMA")) {
                        handleShow();
                        fetchDocContent("FEMA");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "FEMA"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },
                  {
                    label: "Latest Audited financials/ ITR",
                    required: true,

                    onCancelClick: () => {
                      handleDocumentDelete("AFS");
                    },
                    onClick: () => {
                      // handleDocumentSelect("Latest Audited financials/ ITR");
                      // handleDocType("AFS");
                      if (!docObjects.some((doc) => doc.doc_type === "AFS")) {
                        handleDocumentSelect("Latest Audited financials/ ITR");
                        handleDocType("AFS");
                      }
                    },
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "AFS")) {
                        handleShow();
                        fetchDocContent("AFS");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "AFS"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },
                  {
                    label: "Cancelled Cheque / Bank Statement",
                    required: true,

                    onCancelClick: () => {
                      handleDocumentDelete("CC");
                    },
                    onClick: () => {
                      // handleDocumentSelect("Cancelled Cheque");
                      // handleDocType("CC");
                      if (!docObjects.some((doc) => doc.doc_type === "CC")) {
                        handleDocumentSelect("Cancelled Cheque");
                        handleDocType("CC");
                      }
                    },
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "CC")) {
                        handleShow();
                        fetchDocContent("CC");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "CC"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },

                  {
                    label: "Others",

                    onCancelClick: () => {
                      handleDocumentDelete("OT");
                    },
                    onClick: () => {
                      handleDocumentSelect("Others");
                      handleDocType("OT");
                    },
                    onViewClick: () => {
                      if (docObjects.some((doc) => doc.doc_type === "OT")) {
                        //
                        handleViewModalShow("OT");
                      }
                    },
                    style: {
                      backgroundColor: docObjects.some(
                        (doc) => doc.doc_type === "OT"
                      )
                        ? "#9ef89e"
                        : "",
                    },
                  },
                ]}
              />
              <DisplayBox display={state.data.is_msme === true}>
                <>
                  <KYCListView
                    items={[
                      {
                        label: "MSME Certificate",
                        required: true,

                        onCancelClick: () => {
                          handleDocumentDelete("MSME");
                        },
                        onClick: () => {
                          handleDocumentSelect("MSME Certificate");
                          handleDocType("MSME");
                        },
                        onViewClick: () => {
                          if (
                            docObjects.some((doc) => doc.doc_type === "MSME")
                          ) {
                            handleShow();
                            fetchDocContent("MSME");
                          }
                        },
                        style: {
                          backgroundColor: docObjects.some(
                            (doc) => doc.doc_type === "MSME"
                          )
                            ? "#9ef89e"
                            : "",
                        },
                      },
                    ]}
                  />
                </>
              </DisplayBox>
            </Col>
            <Col md={6}>
              <Col
                // className="d-flex align-items-center justify-content-center"
                md={12}
                style={{
                  marginBottom: "70%",
                  marginTop: "30%",
                  // marginLeft: "35%",
                }}
              >
                <Row className="text-center">
                  <Col md={12}>
                    {selectedDocument ? selectedDocument : "Select a Document"}
                  </Col>
                </Row>

                <Col md={12} className="mt-2 mb-2">
                  <Form.Control
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <Row className="text-center">
                    <Col md={12}>
                      <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Control
                          type="file"
                          multiple
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleFileWithSizeLimit(e, undefined, handleImage)
                          }
                          ref={fileInputRef}
                          accept="pdf, jpeg, png, jpg"
                        />
                      </Form.Group>
                    </Col>
                    <Row className="text-center">
                      <Col md={12}>
                        {selectedFile ? selectedFile.name : " "}
                      </Col>
                    </Row>
                  </Row>
                </Col>

                <Row className="text-center">
                  <Col md={12}>
                    {uploading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      ></Spinner>
                    ) : (
                      <Button
                        label={buttonText}
                        type="button"
                        onClick={handleAPI}
                        disabled={uploadDisabled}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12} className="text-end">
                    {isFunctionRunning || chkNavigate ? (
                      <Spinner
                        animation="border"
                        variant="primary"
                        role="status"
                      />
                    ) : (
                      <Button
                        label="Save & Next"
                        type="button"
                        onClick={() => checkNavigate()}
                      ></Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Document View :: {selectedType + " "} {docNameType}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="text-center">
              {showSpinner ? (
                <Spinner variant="primary" animation="border" />
              ) : //
              fileType === "pdf" ? (
                <iframe
                  src={imageData}
                  title="Document viewer"
                  style={{ width: "400px", height: "300px" }}
                />
              ) : (
                <Image
                  src={imageData}
                  style={{ width: "600px", height: "400px" }}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={viewOtherModalShow}
        onHide={handleViewOtherModalClose}
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Others Document Views</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showSpinner ? (
            <Row>
              <Col className="text-center">
                <Spinner variant="primary" animation="border" />
              </Col>
            </Row>
          ) : (
            otherFiles.map((file, index) => (
              <React.Fragment key={index}>
                <Row>
                  <Col className="text-center">
                    {file.type === "pdf" ? (
                      <iframe
                        src={file.url}
                        title={`Document viewer ${index + 1}`}
                        style={{ width: "400px", height: "300px" }}
                      />
                    ) : (
                      <Image
                        src={file.url}
                        style={{ width: "600px", height: "400px" }}
                        alt="hello"
                      />
                    )}
                  </Col>
                </Row>
                {index !== otherFiles.length - 1 && (
                  <hr style={{ margin: "10px 0" }} />
                )}
              </React.Fragment>
            ))
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EntityLLPKYC;
