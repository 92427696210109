import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../../components/common/Input";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import BankAccountType from "../../../../../components/common/dropdowns/BankAccountType";
import { BankDetailsType } from "./types/BankDetailsType";
import LocalBankCurrencies from "../../../../../components/common/dropdowns/LocalBankCurrencies";
import Button from "../../../../../components/common/Button";
import CorrBankAccountDetails from "../../../../guestUser/common/components/BankDetails/CorrBankAccountDetails";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { createLocalBankAction } from "../redux/action/createLocalBankAction";
import { useEffect, useState } from "react";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import SectionHeading from "../../../../../components/common/SectionHeading";
import {
  ADDRESS_REGEX,
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  EMAIL_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
  SWIFTCODE_REGEX,
  NON_INDIAN_REGEX,
} from "../../../../constants/regexPatterns";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { getAllCorrBankAction } from "../redux/action/getAllCorrBankAction";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";
import BankAccountTypeWOEEFC from "../../../../../components/common/dropdowns/BankAccountTypeWOEEFC";
import BankAccountTypeWOthers from "../../../../../components/common/dropdowns/BankAccountTypeWOthers";

const OpsAddLocalBankPage: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  let role = getAppSelector.data.business_participant_type;
  let is_counterparty;

  // // console.log(getAppSelector.data.is_counterparty, "countery party true");
  const createLocalBankSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.createLocalBank
  );

  const isLocalBankActiveSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.isLocalBankFormActive.isActive
  );
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm<BankDetailsType>({ shouldUnregister: true });

  const [localBankId, setLocalBankId] = useState(0);
  const [corrBanks, setCorrBanks] = useState([]);
  const [showLocalBankSuccess, setShowLocalBankSuccess] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [btnText, setBtnText] = useState("Save Details");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [declarationValue, setDeclarationValue] = useState("");
  const [declarationError, setDeclarationError] = useState(false);
  const localCurrency = watch("currency_id");
  const [hideEEFC, setHideEEFC] = useState(false);
  const [ifscCode, setIfscCode] = useState("");
  const country = watch("bank_address_country_id");
  const isCountryIndia = Number(watch("bank_address_country_id")) === 239;

  useEffect(() => {
    if (!isLocalBankActiveSelector) {
      navigate(`/currency-bank-details/${customerId}`);
    }
  }, []);

  useEffect(() => {
    if (localCurrency == 12) {
      // console.log("true");
      setHideEEFC(true);
    } else {
      // console.log("false");
      setHideEEFC(false);
    }
  }, [localCurrency]);

  useEffect(() => {
    setLocalBankId(0);
    // console.warn("here--->", corrBanks);
    // console.warn(localBankId);
    if (localBankId) {
      dispatch(getAllCorrBankAction(localBankId));
    }
  }, [dispatch, localBankId]);

  useEffect(() => {
    // console.log(declarationValue, "check");
  }, []);

  const accountNumber = watch("bank_account_number");
  const confirmAccountNumber = watch("re_enter_bank_account_number");
  const bankAccountType = watch("account_type");

  const handleIfscCodeChange = (e: any) => {
    const value = e.target.value.toUpperCase();
    setIfscCode(value);
    setValue("ifsc_code", value);
  };

  const handleSwiftCodeChange = (e: any) => {
    const value = e.target.value.toUpperCase();
    setValue("swift_code", value);
  };

  const handleBankDetailsSubmit: SubmitHandler<BankDetailsType> = (data) => {
    // console.log(declarationValue, ":here");

    if (accountNumber !== confirmAccountNumber) {
      setError("re_enter_bank_account_number", {
        type: "manual",
        message: "Account Number do not match",
      });
      return;
    }

    if (role !== "Financier") {
      if (declarationValue === "") {
        setDeclarationError(true);
        // // console.log("booming");

        return;
      } else {
        setDeclarationError(false);
      }
    }

    setShowSpinner(true);

    const bankDetails = {
      ...data,
      customer_id: customerId,
      undertaking_confirm: declarationValue,
      bank_address_pincode: Number(data.bank_address_pincode),
      bank_address_country_id: Number(data.bank_address_country_id),
      currency_id: Number(data.currency_id),
    };

    // console.log(bankDetails);

    dispatch(createLocalBankAction({ bankDetails, corrBanks })).then(
      (response: any) => {
        if (response.payload.result.error) {
          alert(response.payload.result.error.data.message);
          setShowSpinner(false);
          setBtnDisabled(false);
        } else {
          setShowLocalBankSuccess(true);
          setShowSpinner(false);
          setBtnText("Bank Added!");
          setBtnDisabled(true);
        }
      }
    );
  };

  return (
    <>
      <Container>
        <NavbarVTX />
        <Header />
        <Row>
          <Row>
            <SectionHeading text="ADD SETTLEMENT BANK ACCOUNT" />
          </Row>
          <DisplayBox display={showLocalBankSuccess}>
            <AlertPopup
              variant="success"
              message="Settlement Bank Added Successfully!"
            />
          </DisplayBox>
          <Row>
            <Row>
              <Col md={20} className="bank-account-col">
                <SectionHeading text="LOCAL BANK DETAILS" />
              </Col>
            </Row>

            <Form
              style={{ width: "119%" }}
              onSubmit={handleSubmit(handleBankDetailsSubmit)}
              // disabled={btnDisabled}
            >
              <Row style={{ marginTop: "30px" }}>
                <Col sm={6} className="d-flex align-items-center">
                  <LocalBankCurrencies
                    label="Local bank currency"
                    name="currency_id"
                    register={register}
                    required
                    requiredMessage="Currency is required"
                    error={errors.currency_id?.message}
                    disabled={btnDisabled}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Bank name"
                    name="bank_name"
                    register={register}
                    placeholder="Enter bank name"
                    required
                    regexPattern={ALPHABETS_REGEX}
                    requiredMessage="Bank Name is required"
                    error={errors.bank_name?.message}
                    disabled={btnDisabled}
                  />
                </Col>

                <Col sm={6}>
                  {getAppSelector.data.is_counterparty === true && (
                    <p style={{ fontSize: "smaller", color: "red" }}>
                      Following fields are not applicable for Counter Party
                      Applications
                    </p>
                  )}
                  <Input
                    label="Address line 1"
                    name="bank_address_line_1"
                    register={register}
                    regexPattern={ADDRESS_REGEX}
                    placeholder="Enter address line 1"
                    required
                    requiredMessage="Address line 1 is required"
                    error={errors.bank_address_line_1?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "1px" }}>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Branch name"
                    name="branch_name"
                    regexPattern={ALPHABETS_REGEX}
                    register={register}
                    placeholder="Enter branch name"
                    required
                    requiredMessage="Branch name is required"
                    error={errors.branch_name?.message}
                    disabled={btnDisabled}
                  />
                </Col>
                <Col sm={6}>
                  <Input
                    label="Address line 2"
                    name="bank_address_line_2"
                    register={register}
                    // regexPattern={ADDRESS_REGEX}
                    placeholder="Enter address line 2"
                    // required
                    // requiredMessage="Email is required"
                    error={errors.bank_address_line_2?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Bank account no"
                    type="password"
                    name="bank_account_number"
                    register={register}
                    placeholder="Enter bank account no"
                    required
                    requiredMessage="Account No/ IBAN is required"
                    error={errors.bank_account_number?.message}
                    disabled={btnDisabled}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="District/City Area"
                    name="bank_address_district"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter district"
                    // required
                    // requiredMessage="city is required"
                    error={errors.bank_address_district?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Re-enter Bank account no"
                    name="re_enter_bank_account_number"
                    // type="password"
                    register={register}
                    placeholder="Enter re-enter bank account no"
                    required
                    requiredMessage="Bank account number/IBAN is required"
                    error={errors.re_enter_bank_account_number?.message}
                    disabled={btnDisabled}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="State/County"
                    name="bank_address_state"
                    register={register}
                    placeholder="Enter state"
                    regexPattern={ALPHABETS_REGEX}
                    // required
                    // requiredMessage="state is required"
                    error={errors.bank_address_state?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Name as per bank account"
                    name="bank_account_name"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter name as per bank account"
                    disabled={btnDisabled}
                    // required
                    // requiredMessage="Bank account name is required"
                    // error={errors.bank_account_name?.message}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <CountryDropdown
                    label="Country"
                    name="bank_address_country_id"
                    register={register}
                    // required
                    // requiredMessage="pincode is required"
                    error={errors.bank_address_country_id?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="IFSC/Sort Code/ABA"
                    name="ifsc_code"
                    register={register}
                    // regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter IFSC/Sort code/ABA"
                    required
                    requiredMessage="IFSC/Sort/ABA is required"
                    error={errors.ifsc_code?.message}
                    disabled={btnDisabled}
                    onChange={handleIfscCodeChange}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="City/Town/Locality"
                    name="bank_address_city"
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    // required
                    placeholder="Enter City"
                    // requiredMessage="city is required"
                    error={errors.city?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Swift code /BIC"
                    name="swift_code"
                    register={register}
                    placeholder="Enter swift code"
                    required
                    // regexPattern={SWIFTCODE_REGEX}
                    requiredMessage="Swift Code is required"
                    error={errors.swift_code?.message}
                    disabled={btnDisabled}
                    onChange={handleSwiftCodeChange}
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Pin Code/ Postal Code/ Zip"
                    name="bank_address_pincode"
                    register={register}
                    placeholder="Enter pincode/zipcode"
                    regexPattern={
                      isCountryIndia ? PINCODE_REGEX : NON_INDIAN_REGEX
                    }
                    // required
                    // requiredMessage="email is required"
                    error={errors.bank_address_pincode?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  {role === "Financier" ? (
                    <BankAccountTypeWOthers
                      label="Account Type"
                      name="account_type"
                      register={register}
                      required
                      requiredMessage="Account type is required"
                      error={errors.account_type?.message}
                      disabled={btnDisabled}
                    />
                  ) : hideEEFC ? (
                    <BankAccountTypeWOEEFC
                      label="Account Type"
                      name="account_type"
                      register={register}
                      required
                      requiredMessage="Account type is required"
                      error={errors.account_type?.message}
                      disabled={btnDisabled}
                    />
                  ) : (
                    <BankAccountType
                      label="Account Type"
                      name="account_type"
                      register={register}
                      required
                      requiredMessage="Account type is required"
                      error={errors.account_type?.message}
                      disabled={btnDisabled}
                    />
                  )}
                </Col>
                {bankAccountType === "Others" && (
                  <Input
                    label={"If Others,"}
                    name={"other_account_type"}
                    register={register}
                    placeholder="Please Specify Account Type"
                    required
                    error={errors.other_account_type?.message}
                    disabled={btnDisabled}
                  />
                )}
              </Row>

              <Row>
                <Col md={20} className="bank-account-col">
                  <SectionHeading text="BANK OFFICIALS DETAILS" />
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                {getAppSelector.data.is_counterparty === true && (
                  <p style={{ fontSize: "smaller", color: "red" }}>
                    Following fields are not applicable for Counter Party
                    Applications
                  </p>
                )}
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official Name"
                    name="bank_official_name"
                    required={
                      getAppSelector.data.is_counterparty !== true ||
                      role !== "Financier"
                    }
                    register={register}
                    regexPattern={ALPHABETS_REGEX}
                    placeholder="Enter official name"
                    error={errors.bank_official_name?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  {/* <Input
                    label="Official Phone"
                    name="bank_official_phone"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    placeholder="Enter official phone"
                    error={errors.bank_official_phone?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  /> */}
                  <PhoneInputWithDropdown
                    label="Official Phone"
                    name="bank_official_phone"
                    required={
                      getAppSelector.data.is_counterparty !== true ||
                      role !== "Financier"
                    }
                    register={register}
                    regexPattern={PHONE_REGEX}
                    isdCodeName={"phone_country_code"}
                    isdCodeRegister={register}
                    placeholder="Enter official phone"
                    error={errors.bank_official_phone?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    // required
                    // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="d-flex align-items-center">
                  <Input
                    label="Official Email"
                    name="bank_official_email"
                    required={
                      getAppSelector.data.is_counterparty !== true ||
                      role !== "Financier"
                    }
                    register={register}
                    regexPattern={EMAIL_REGEX}
                    placeholder="Enter official email"
                    error={errors.bank_official_email?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                  />
                </Col>
                <Col sm={6} className="d-flex align-items-center">
                  {/* <Input
                    label="Official Mobile"
                    name="bank_official_mobile"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    placeholder="Enter official mobile"
                    error={errors.bank_official_mobile?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true
                        ? true
                        : false
                    }
                  /> */}
                  <PhoneInputWithDropdown
                    label="Official Mobile"
                    name="bank_official_mobile"
                    register={register}
                    regexPattern={PHONE_REGEX}
                    isdCodeName={"phone_country_code"}
                    isdCodeRegister={register}
                    placeholder="Enter official mobile"
                    error={errors.bank_official_mobile?.message}
                    disabled={
                      getAppSelector.data.is_counterparty === true ||
                      btnDisabled
                    }
                    // required
                    // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
                  />
                </Col>
              </Row>
              {role !== "Financier" && (
                <>
                  <Col
                    sm={12}
                    style={{
                      fontSize: "86%",
                      color: "#214382",
                      marginTop: "3%",
                    }}
                  >
                    <Form.Group
                      controlId="undertaking_confirm"
                      // {...register("undertaking_confirm", {
                      //   required: "Please Select",
                      // })}
                    >
                      <Form.Check
                        disabled={btnDisabled}
                        type="radio"
                        label="We undertake and confirm that the above-mentioned details are of
                  our Working capital account, or "
                        value="Yes"
                        name="undertaking_confirm"
                        onChange={(e) => setDeclarationValue(e.target.value)}
                      />
                      <Form.Check
                        disabled={btnDisabled}
                        type="radio"
                        label="We undertake and confirm that we do not avail any working
                      capital facility from any Bank, NBFC, or other financial
                      institutions. The above details are of our operative account for
                      carrying out day to day operations of our firm/ company."
                        value="No"
                        name="undertaking_confirm"
                        onChange={(e) => setDeclarationValue(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  {declarationError && (
                    <p className="m-2" style={{ color: "#d64161" }}>
                      Please accept the Declaration
                    </p>
                  )}
                </>
              )}

              <Row>
                <Col md={20} className="text-end">
                  {showSpinner ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <Button
                      label={btnText}
                      type="submit"
                      disabled={btnDisabled}
                    ></Button>
                  )}
                </Col>
              </Row>
            </Form>
            <CorrBankAccountDetails
              setCorrBanks={setCorrBanks}
              corrBanks={corrBanks}
            />
          </Row>
          <Row>
            <Col md={20} className="text-end">
              <Button
                label="Save Details"
                type="submit"
                onClick={() => {
                  navigate(`/currency-bank-details/${customerId}`);
                }}
              ></Button>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default OpsAddLocalBankPage;
