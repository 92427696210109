import { getSessionId } from "../../../utils/tokenHandlers";
import axios from "axios";

export const fetchCommunicationAddress = async (customerId: number) => {
  const sessionId = getSessionId();
  const filter = JSON.stringify([
    ["customer_id", "in", [customerId]],
    ["type", "=", "Communication"],
  ]);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer_address/`,
      {
        params: {
          filter,
          query:
            "{id,customer_id,type,line_1,line_2,city,district,state,pincode,country_id{name},phone_country_code,phone,email,billing_gstn,business_billing_preference_email,business_communication_preference_email, business_billing_gstn}",
        },
      },
      {
        params: {
          o_ses_id: sessionId,
        },
      }
    );
    return response;
  } catch (err) {
    return err;
  }
};
