import axios from "axios";
import { getSessionId } from "../../../../../utils/tokenHandlers";

export const getAllApplicationStatus = (
  appStage: string,
  currentPage: number
) => {
  // // console.log(sessionId, "getapp", appStage);
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer`,
      {
        params: {
          filter: `[('stage', '=' ,"${appStage}"), ('is_counterparty', '!=','true')]`,
          page: currentPage,
          query:
            "{id,stage,application_uid,is_counterparty,referred_by, business_participant_type,business_registration_country_id{name},customer_contact_name,  customer_contact_mobile_country_code,customer_contact_mobile,  customer_contact_email,employee_declaration_accepted,employee_id,employee_name{name}, submitted_date,business_gst_details_fetch_over_api,business_gst_api_results_available,business_gst,business_constitution_type,business_name, business_is_ftr_ftnr,business_cin_no,business_ckycno,business_commencement_date,business_iecno,business_legal_entity_identifier,business_listing_info_ids,business_nature,business_nature_activity,business_registered_with,business_registration_date, business_registration_place, business_statusholder,business_unit_location,business_website}",
        },
      },
      {
        params: {
          o_ses_id: sessionId,
        },
      }
    )
    .then((response) => {
      // console.log("response",response)
      return response;
    })
    .catch((err) => err);
};
