import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Image,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import { BankDetailsType } from "./types/BankDetailsType";
import Button from "../../../../../components/common/Button";
import IndustryType from "../../../../../components/common/dropdowns/IndustryType";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import TextArea from "../../../../../components/common/Textarea";
import RmName from "../../../../../components/common/dropdowns/RmName";
import RmNameType from "../../../../../components/common/dropdowns/RmName";
import { OfficialUseOnlyType } from "./types/OfficialUseOnlyType";
import { getRmName } from "../../../../apis/listOfValues/getRmName";
import {
  getSessionId,
  getUserId,
  getUserRole,
  getUserUid,
  getUsername,
} from "../../../../../utils/tokenHandlers";
import { useActionData, useNavigate, useParams } from "react-router-dom";
import { getIndustryType } from "../../../../apis/listOfValues/getIndustryType";
import DatePicker from "../../../../../components/common/DatePicker";
import RadioButton from "../../../../../components/common/RadioButton";
import { ftnrOptions } from "../../../../constants/constants";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { updateAppAction } from "../redux/action/updateAppAction";
import SubIndustryType from "../../../../../components/common/dropdowns/SubIndustryType";
import { getSubIndustryType } from "../../../../apis/listOfValues/getSubIndustryType";
import { getAppAction } from "../redux/action/getAppAction";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { createEntity } from "../apis/createEntity";
import { AuthoriseErrorModal } from "../components/AuthoriseErrorModal";
import { checkValidation } from "../apis/checkValidation";
import { ValidationErrorModal } from "../components/ValidationErrorModal";
import { ALPHANUMERIC_REGEX } from "../../../../constants/regexPatterns";
import { updateStageAction } from "../redux/action/updateStageAction";
import KYCListView from "../../../../../components/common/KYCDocsList";
import handleFileWithSizeLimit from "../../../../constants/fileSizeLimit";
import { uploadAttachment } from "../apis/uploadAttachment";
import { updateMasterAgreement } from "../apis/updateMasterAgreement";
import axios from "axios";
import OpsMakerCheckerName from "../../../../../components/common/dropdowns/OpsMakerCheckerName";
import { getOpsEmpID } from "../apis/getOPSEmpID";
import { deleteDocument } from "../../../../guestUser/ppApplication/redux/action/deleteDocument";
import { deleteCustomerDocument } from "../apis/deleteCustomerDocuments";
import { fieldValidationAction } from "../redux/action/fieldValidation";
import RiskCategory from "../../../../../components/common/dropdowns/RiskCategory";

const OpsOfficeUsePage: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  let stage = "move_stage_to_authorisation";
  // let checkerStage = "move_stage_from_authorisation_to_register";
  const useRole = getUserRole();
  const navigate = useNavigate();
  const userName = getUsername();
  const userUid = getUserUid();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm<OfficialUseOnlyType>({
    shouldUnregister: true,
  });

  const dispatch = useDispatch<AppDispatch>();

  const [showSpinner, setShowSpinner] = useState(false);
  const [rmData, setRmData] = useState([]);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [subIndustryTypes, setSubIndustryTypes] = useState([]);
  const [authoriseError, setAuthoriseError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [validationError, setValidationError] = useState("");
  const [ftrValue, setFTRvalue] = useState("");
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const industryType = watch("business_parent_industry_type");
  const subIndustryTypess = watch("business_industry_type");
  const ftrFTNR = watch("business_is_ftr_ftnr");
  const ftnrRemarks = watch("business_ftnr_remarks");
  const makerSubmitDate = watch("maker_submitted_date");
  const [checkerId, setCheckerId] = useState("");
  const [checkerName, setCheckerName] = useState("");

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  const isMaker = useRole === "maker";
  const appStage = getAppSelector.data.stage;
  // // console.log(isMaker, "hiii.............");

  // const isChecker = useRole == "checker";
  // // console.log(isChecker,"checker")
  useEffect(() => {
    // console.log(appStage);
    if (getAppSelector.data) {
      reset({
        business_is_ftr_ftnr: getAppSelector.data.business_is_ftr_ftnr,
        business_ftnr_remarks: getAppSelector.data.business_ftnr_remarks,
        business_parent_industry_type:
          getAppSelector.data.business_parent_industry_type,
        checker_employee_id: getAppSelector.data.checker_employee_id,
        checker_employee_name: getAppSelector.data.checker_employee_name,
        // checker_submitted_date: getAppSelector.data.checker_submitted_date,
        employee_id: getAppSelector.data.employee_id,
        employee_name: getAppSelector.data.employee_name,
        maker_employee_id: getAppSelector.data.maker_employee_id,
        maker_employee_name: getAppSelector.data.maker_employee_name,
        business_industry_type: getAppSelector.data.business_industry_type,
        risk_category: getAppSelector.data.risk_category,
        // maker_submitted_date: getAppSelector.data.maker_submitted_date,

        submitted_date: getAppSelector.data.submitted_date,
      });
    }
    if (
      appStage === "In Checker Verification" ||
      appStage === "In Customer Registration" ||
      appStage === "Customer Registered"
    ) {
      reset({
        maker_submitted_date: getAppSelector.data.maker_submitted_date,
      });
    }
    if (
      appStage === "In Customer Registration" ||
      appStage === "Customer Registered"
    ) {
      reset({
        checker_submitted_date: getAppSelector.data.checker_submitted_date,
        maker_submitted_date: getAppSelector.data.maker_submitted_date,
      });
    }
  }, [getAppSelector]);

  useEffect(() => {
    // // console.log("rm name", getAppSelector.data.employee_name);
    // // console.log("rm id", getAppSelector.data.employee_id);
    // // console.log("rm date", getAppSelector.data.submitted_date);
    setValue("employee_name", getAppSelector.data.employee_name);
    setValue("employee_id", getAppSelector.data.employee_id);
    setValue("submitted_date", getAppSelector.data.submitted_date);
    setValue("maker_employee_name", getAppSelector.data.maker_employee_name);
    setValue("maker_employee_id", getAppSelector.data.maker_employee_id);
    setValue(
      "business_industry_type",
      getAppSelector.data.business_industry_type
    );
    setValue("risk_category", getAppSelector.data.risk_category);
    // setValue("maker_submitted_date", getAppSelector.data.maker_submitted_date);
    setTimeout(() => {
      setValue(
        "business_parent_industry_type",
        getAppSelector.data.business_parent_industry_type
      );

      setValue(
        "business_industry_type",
        getAppSelector.data.business_industry_type
      );

      setValue("risk_category", getAppSelector.data.risk_category);
      setValue(
        "business_is_ftr_ftnr",
        getAppSelector.data.business_is_ftr_ftnr
      );
      setValue(
        "business_ftnr_remarks",
        getAppSelector.data.business_ftnr_remarks
      );
      // console.log(ftrFTNR);
    }, 500);
  }, [getAppSelector]);

  useEffect(() => {
    getRmName(getSessionId()).then((response) => {
      setRmData(response.result.result);
    });

    getIndustryType(getSessionId()).then((response) => {
      setIndustryTypes(response.result.result);
      dispatch(getAppAction(customerId));
    });

    const date = new Date();
    // console.log(date);
    reset({
      submitted_date: date.toISOString().split("T")[0],
      maker_submitted_date: date.toISOString().split("T")[0],
      checker_submitted_date: date.toISOString().split("T")[0],
    });
  }, []);

  const selectedRmId = watch("employee_name");
  const selectedMakerId = watch("maker_employee_name");
  const selectedCheckerId = watch("checker_employee_name");
  const selectedIndustryType = watch("business_parent_industry_type");
  const ftnrRadioSelectedValue = watch("business_is_ftr_ftnr") === "FTNR";

  useEffect(() => {
    if (selectedIndustryType) {
      getSubIndustryType(selectedIndustryType, getSessionId()).then(
        (response) => {
          setSubIndustryTypes(response.result.result);
          setValue(
            "business_industry_type",
            getAppSelector.data.business_industry_type
          );
        }
      );
    }
  }, [selectedIndustryType]);

  useEffect(() => {
    // Check if userUid exists before proceeding
    if (useRole === "maker" && userUid) {
      // console.log(userUid);
      // setValue("maker_employee_name", userUid);
      // // console.log("selectedMakerId", selectedMakerId);

      // if (selectedMakerId) {
      //   // alert("hey");
      //   const getEmpId = getOpsEmpID(Number(userUid));
      //   if (getEmpId) {
      //     // console.log("Api --", getEmpId);
      //   }
      // }
      const fetchOpsEmpID = async () => {
        try {
          const response = await getOpsEmpID(Number(userUid));
          // console.log("EMP Name API Response:", response);
          try {
            const empId = response.result.result[0].barcode;
            const empName = response.result.result[0].id;
            // console.log(empId);
            if (empId && empName) {
              setValue("maker_employee_id", empId);
              setValue("maker_employee_name", empName);
            }
          } catch (err) {
            // console.log("error getting barcode", err);
          }
        } catch (error) {
          // console.error("Error fetching Ops Emp ID:", error);
        }
      };

      fetchOpsEmpID();
    } else if (useRole === "checker" && userUid) {
      // console.log(userUid);
      // setValue("checker_employee_name", userUid);
      // // console.log("selectedCheckerId", selectedCheckerId);

      // if (selectedMakerId) {
      //   // alert("hey");
      //   const getEmpId = getOpsEmpID(Number(userUid));
      //   if (getEmpId) {
      //     // console.log("Api --", getEmpId);
      //   }
      // }
      const fetchOpsEmpID = async () => {
        try {
          const response = await getOpsEmpID(Number(userUid));
          // console.log("EMP Name API Response:", response);
          try {
            const empId = response.result.result[0].barcode;
            const empName = response.result.result[0].id;
            // console.log(empId);
            if (empId && empName) {
              setValue("checker_employee_id", empId);
              setValue("checker_employee_name", empName);
              setTimeout(() => {
                setValue("checker_employee_id", empId);
                setValue("checker_employee_name", empName);
              }, 500);
              setCheckerName(empName);
              setCheckerId(empId);
            }
          } catch (err) {
            // console.log("error getting barcode", err);
          }
        } catch (error) {
          // console.error("Error fetching Ops Emp ID:", error);
        }
      };

      fetchOpsEmpID();
    }
  }, [useRole, userUid, selectedMakerId]);

  // useEffect(() => {
  //   // console.log("here");
  // }, [gotEmpName]);

  useEffect(() => {
    if (selectedRmId && rmData.length > 0) {
      const rmDetails: any = rmData.filter((rm: any) => rm.id == selectedRmId);
      // console.log("RM DETAILS", rmDetails);
      setValue("employee_id", rmDetails[0].barcode);
    }
  }, [selectedRmId]);

  // useEffect(() => {
  //   if (selectedRmId && rmData.length > 0) {
  //     const rmDetails: any = rmData.filter((rm: any) => rm.id == selectedRmId);

  //     // Check if rmDetails is not empty before accessing properties
  //     if (rmDetails.length > 0) {
  //       setValue("employee_id", rmDetails[0].barcode);
  //     }
  //   }
  // }, [selectedRmId]);

  // useEffect(() => {
  //   if (selectedMakerId && rmData.length > 0) {
  //     const rmDetails: any = rmData.filter(
  //       (rm: any) => rm.id == selectedMakerId
  //     );
  //     setValue("maker_employee_id", rmDetails[0].barcode);
  //   }
  // }, [selectedMakerId]);

  // useEffect(() => {
  //   if (selectedCheckerId && rmData.length > 0) {
  //     const rmDetails: any = rmData.filter(
  //       (rm: any) => rm.id == selectedCheckerId
  //     );
  //     setValue("checker_employee_id", rmDetails[0].barcode);
  //   }
  // }, [selectedCheckerId]);

  const handleOfficialUseOnlySubmit = (officialUseDetails: any) => {
    // console.log(officialUseDetails, "OU details");

    if (useRole === "checker" && appStage === "In Checker Verification") {
      setShowSpinner(true);

      // console.log("userRole", useRole);
      officialUseDetails.business_industry_type = Number(
        officialUseDetails.business_industry_type
      );
      officialUseDetails.employee_name = Number(
        officialUseDetails.employee_name
      );
      officialUseDetails.maker_employee_name = Number(
        officialUseDetails.maker_employee_name
      );
      officialUseDetails.checker_employee_name = Number(checkerName);

      officialUseDetails.checker_employee_id = checkerId;

      // console.log(officialUseDetails, "OU details");

      dispatch(
        updateAppAction({
          customerId,
          updateCustomerData: officialUseDetails,
        })
      ).then(async (resp) => {
        // console.log("resp", resp);
        if (resp.payload.error) {
          alert(resp.payload.error.data.message);
        } else {
          try {
            let stage = "move_stage_from_authorisation_to_register";
            dispatch(fieldValidationAction({ customerId, stage })).then(
              async (response) => {
                // console.log("Api resp field validation-->", response);
                if (response.payload.error) {
                  setShowSpinner(false);
                  // console.log("first api error");
                  const validationErr = response.payload.error.data.message;
                  // console.warn(validationErr);
                  openValidationModal(validationErr);
                } else {
                  alert("Success");
                  navigate("/ops/dashboard");
                  // ? the below code is commented as the create entity is called in the field validation api itself
                  // const result = await createEntity(customerId);
                  // if (result.result.code === 400) {
                  //   const errMsg = result.result.message;
                  //   // console.log("seonnd api error");
                  //   // console.warn(errMsg);
                  //   if (errMsg) {
                  //     openModal(errMsg);
                  //     setShowSpinner(false);
                  //   }
                  // } else {
                  //   alert("Success");
                  //   navigate("/ops/dashboard");
                  // }
                }
              }
            );
            //
          } catch (error) {
            // console.error("An error occurred", error);
            setShowSpinner(false);
          }
          //
        }
      });
    } else if (
      (useRole === "maker" && appStage === "In Maker Verification") ||
      appStage === "Sent back to maker"
    ) {
      setShowSpinner(true);

      officialUseDetails.business_industry_type = Number(
        officialUseDetails.business_industry_type
      );
      officialUseDetails.employee_name = Number(
        officialUseDetails.employee_name
      );
      officialUseDetails.maker_employee_name = Number(
        officialUseDetails.maker_employee_name
      );

      if (officialUseDetails.checker_employee_name === "0") {
        officialUseDetails.checker_employee_name = null;
      } else {
        officialUseDetails.checker_employee_name = Number(
          officialUseDetails.checker_employee_name
        );
      }

      // console.log(officialUseDetails);
      // console.log(makerSubmitDate);

      dispatch(
        updateAppAction({
          customerId,
          updateCustomerData: officialUseDetails,
        })
      ).then(async (resp) => {
        // console.log("resp", resp);
        if (resp.payload.error) {
          alert(resp.payload.error.data.message);
        } else {
          dispatch(getAppAction(customerId)).then(async (resp) => {
            setValue(
              "business_industry_type",
              getAppSelector.data.business_industry_type
            );
            // console.log(
            //   resp.payload.result.master_agreeement_attachment_ids.length,
            //   "-here"
            // );
            if (
              resp.payload.result.master_agreeement_attachment_ids.length ===
                0 &&
              getAppSelector.data.is_counterparty !== true
            ) {
              alert("Please upload Master Agreement to continue!");
              // console.log("industry type:", industryType);
              // console.log("sub inds:", subIndustryTypess);
              // console.log("ftnr:", ftrFTNR);
              window.location.reload();
              setShowSpinner(false);
            } else {
              try {
                // const result = await checkValidation(customerId);
                // if (result.data.result.code === 400) {
                //   // console.warn(result, "here");
                //   const validationErr = result.data.result.message;
                //   if (validationErr) {
                //     openValidationModal(validationErr);
                //     setShowSpinner(false);
                //   }
                // } else {
                //   UpdateStageAndNavigate();
                // }
                dispatch(fieldValidationAction({ customerId, stage })).then(
                  (response) => {
                    // console.log("Api resp-->", response);
                    if (response.payload.error) {
                      setShowSpinner(false);
                      const validationErr = response.payload.error.data.message;
                      // console.warn(validationErr);
                      openValidationModal(validationErr);
                    } else {
                      setShowSpinner(false);
                      alert("Success");
                      navigate(`/ops/dashboard`);
                    }
                  }
                );
              } catch (error) {
                // console.error("An error occurred", error);
                setShowSpinner(false);
              }
            }
          });
        }
      });
    } else {
      alert("Something went wrong!");
    }
  };

  const UpdateStageAndNavigate = () => {
    dispatch(updateStageAction({ customerId, stage })).then((response: any) => {
      // console.log(response);
      navigate(`/ops/dashboard`);
    });
  };

  const openValidationModal = (validationErr: string) => {
    setValidationError(validationErr);
    setShowValidationModal(true);
  };

  const openModal = (errMsg: string) => {
    setAuthoriseError(errMsg);
    setShowModal(true);
  };
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [docName, setDocName] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showUploadSpinner, setShowUploadSpinner] = useState(false);
  const handleMasterAgreementClick = async () => {
    setShowUpload(true);
  };
  const [image, setImage] = useState<string | Blob>("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      // console.log(selectedFile);
      setImage(selectedFile);
      // // console.warn("docname-->", selectedFile.name);
      localStorage.setItem("fileName", selectedFile.name);
      // console.warn("doc-->", localStorage.getItem("fileName"));
      setDocName(selectedFile.name);
    } else {
      // console.warn("No files selected.");
    }
  };
  const [docUplaoded, setDocUploaded] = useState(false);
  const handleAPI = async () => {
    try {
      localStorage.setItem("customerId", `${customerId}`);
      if (docName && image) {
        const sessionId = getSessionId();
        // setUploading(true);
        const response = await uploadAttachment(docName, image, sessionId);
        // console.log("API response::", response);
        if (response.data.message === "Success") {
          const docId = response.data.data.attachement;
          // console.log("docId-->", docId);

          if (docId) {
            const signedAppUpload = await updateMasterAgreement(
              customerId,
              docId
            );
            if (signedAppUpload.data.result == true) {
              setFileUploaded(true);
              setShowUpload(false);
            } else {
              setFileUploaded(false);
              alert("Something went wrong, Please contact Admin");
            }
          }
          // fetchDocsDatas();
          // localStorage.clear();
          setDocUploaded(true);

          if (fileInputRef.current) {
            fileInputRef.current.value = "";
            // setButtonText("Upload");
          }

          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        } else if (response.type === "uploadAttachment/rejected") {
          // navigate("/");
        }
      }
    } catch (error) {
      // console.error("An error occurred--:", error);
    }
  };

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [customerId, dispatch, fileUploaded]);
  const sessionId = getSessionId();
  const [showModalSpinner, setShowModalSpinner] = useState(false);
  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");

  const handleDocumentDelete = async () => {
    if (
      appStage === "Sent back to maker" ||
      appStage === "In Maker Verification"
    ) {
      const docId = getAppSelector.data.master_agreeement_attachment_ids[0];
      // console.log(docId);
      if (docId && appStage === "In Maker Verification") {
        const updateCustomerData = {
          master_agreeement_attachment_ids: [],
        };
        const deleteDocument = await deleteCustomerDocument(
          customerId,
          updateCustomerData
        ).then((resp) => {
          // console.log(resp);
          dispatch(getAppAction(customerId)).then((resp) => {
            alert("Master Agreement Deleted!");
          });
        });
      } else {
        alert(
          "Master Agreement can only be deleted by Ops Maker in the Verification Stage"
        );
      }
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  const handleDocumentView = async () => {
    const docId = getAppSelector.data.master_agreeement_attachment_ids[0];
    // console.log(docId);
    const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?o_ses_id=${sessionId}`;
    try {
      setShowModalSpinner(true);
      const response = await axios.get<ArrayBuffer>(apiUrl, {
        responseType: "arraybuffer",
      });

      const dataAsArray = new Uint8Array(response.data);

      if (
        dataAsArray[0] === 0x25 &&
        dataAsArray[1] === 0x50 &&
        dataAsArray[2] === 0x44 &&
        dataAsArray[3] === 0x46
      ) {
        // const pdfBase64 = `data:application/pdf;base64,${btoa(
        //   String.fromCharCode.apply(null, Array.from(dataAsArray))
        // )}`;
        setImageData(apiUrl);
        // console.warn("its a pdf");
        setFileType("pdf");
      } else {
        setShowModalSpinner(true);
        const imageBase64 = `data:image/png;base64,${btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
        setImageData(imageBase64);
        // console.warn("its a img");
        setFileType("img");
      }

      // setShowSpinner(false);
      setShowModalSpinner(false);
    } catch (error) {
      // console.error("Error fetching file:", error);
      setShowModalSpinner(false);
    }
    // console.log("API URL:", apiUrl);
  };

  return (
    <>
      <NavbarVTX />
      <Header />
      <Container>
        <Row>
          <Row>
            <SectionHeading text="FOR OFFICIAL USE ONLY" />
          </Row>
          <Row
            md={12}
            className="text-center justify-content-center align-items-center"
          >
            <Row md={12}>
              {getAppSelector.data.is_counterparty !== true && (
                <Col md={6}>
                  <KYCListView
                    items={[
                      {
                        label: "Master Agreement",
                        required: false,
                        onCancelClick: () => {
                          handleDocumentDelete();
                        },
                        onViewClick: () => {
                          handleShow();
                          handleDocumentView();
                        },
                        onClick: () => {
                          handleMasterAgreementClick();
                        },
                        style: {
                          backgroundColor:
                            getAppSelector.data &&
                            getAppSelector.data
                              .master_agreeement_attachment_ids &&
                            getAppSelector.data.master_agreeement_attachment_ids
                              .length !== 0
                              ? "#9ef89e"
                              : "",
                        },
                      },
                    ]}
                  />
                </Col>
              )}
            </Row>
            <Row className="mt-3" md={12}>
              {getAppSelector.data.is_counterparty !== true && (
                <Col md={6}>
                  {showUpload && useRole === "maker" && (
                    <>
                      <Row md={12}>
                        <Col md={9}>
                          <Form.Group
                            controlId="formFileMultiple"
                            className="mb-3"
                          >
                            <Form.Control
                              type="file"
                              multiple
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleFileWithSizeLimit(
                                  e,
                                  undefined,
                                  handleImage
                                )
                              }
                              ref={fileInputRef}
                              accept="pdf, jpeg, png, jpg"
                              size="sm"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          {showUploadSpinner ? (
                            <Spinner
                              animation="border"
                              role="status"
                              variant="primary"
                            ></Spinner>
                          ) : (
                            <Button
                              label="Upload"
                              type="button"
                              onClick={handleAPI}
                              disabled={
                                appStage === "In Customer Registration" ||
                                appStage === "Customer Registered" ||
                                appStage === "Expired" ||
                                useRole === "checker"
                              }
                              className="btn-sm"
                            />
                          )}
                        </Col>
                      </Row>
                      <Row className="text-center"></Row>
                    </>
                  )}
                </Col>
              )}
            </Row>
          </Row>

          <Form
            style={{ width: "119%" }}
            onSubmit={handleSubmit(handleOfficialUseOnlySubmit)}
          >
            <Row>
              <Col sm={6} className="d-flex align-items-center">
                <IndustryType
                  label="Industry Type"
                  name="business_parent_industry_type"
                  register={register}
                  required
                  requiredMessage="Industry Type is required"
                  error={errors.business_parent_industry_type?.message}
                  disabled={
                    useRole === "checker" ||
                    appStage === "In Customer Registration" ||
                    appStage === "Customer Registered"
                  }
                />
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row style={{ marginTop: "1px" }}>
              <Col sm={6} className="d-flex align-items-center">
                <SubIndustryType
                  label="Sub Industry"
                  name="business_industry_type"
                  register={register}
                  options={subIndustryTypes}
                  required
                  requiredMessage="Sub Industry Type is required"
                  disabled={
                    useRole === "checker" ||
                    appStage === "In Customer Registration" ||
                    appStage === "Customer Registered"
                  }
                />
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row>
              <Col sm={6} className="d-flex align-items-center">
                <RadioButton
                  label="FTR/ NFTR"
                  name="business_is_ftr_ftnr"
                  options={ftnrOptions}
                  register={register}
                  required={useRole === "maker"}
                  //defaultValue="buyer"
                  requiredMessage="FTR/ NFTR is required"
                  error={errors.business_is_ftr_ftnr?.message}
                  onChange={(e) => setFTRvalue(e.target.value)}
                  disabled={
                    useRole === "checker" ||
                    appStage === "In Customer Registration" ||
                    appStage === "Customer Registered"
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="d-flex align-items-center">
                <DisplayBox
                  display={
                    getAppSelector.data.business_is_ftr_ftnr === "FTNR" ||
                    ftrValue === "FTNR"
                  }
                >
                  <TextArea
                    label="FTR/NFTR Remarks"
                    name="business_ftnr_remarks"
                    register={register}
                    placeholder="FTR/NFTR Remarks"
                    required
                    regexPattern={ALPHANUMERIC_REGEX}
                    requiredMessage="NFTR Remark is required"
                    error={errors.business_ftnr_remarks?.message}
                    disabled={
                      useRole === "checker" ||
                      appStage === "In Customer Registration" ||
                      appStage === "Customer Registered"
                    }
                  />
                </DisplayBox>
              </Col>
              <Col sm={6} className="d-flex align-items-center"></Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <RiskCategory
                  name={"risk_category"}
                  label={"Risk Category"}
                  register={register}
                  required={useRole === "maker"}
                  requiredMessage="Risk category is required"
                  error={errors.risk_category?.message}
                  disabled={
                    useRole === "checker" ||
                    appStage === "In Customer Registration" ||
                    appStage === "Customer Registered"
                  }
                />
              </Col>
            </Row>
            <Row>
              <SectionHeading text="DECLARATIONS" />
            </Row>
            <Row>
              <Col md={20} className="mt-4" style={{ color: "#214382" }}>
                <h6>RELATIONSHIP MANAGER</h6>
              </Col>
              <p>
                {/* This account opening form is signed by the customer in presence
                of me. I hereby certify that this application form is complete
                in all respects and relevant documents have been obtained as per
                extant KYC guidelines and performed due diligence to verify the
                genuineness of the customer. */}
                A confirmation from the Relationship Manager is obtained that
                this application form is signed by the participant and is
                complete in all respects and relevant documents have been
                obtained by him/her as per extant KYC guidelines and necessary
                due diligence is performed by him/her to verify the genuineness
                of the participant.
              </p>
            </Row>
            <Row className="justify-content-center">
              <Col sm={4}>
                <RmNameType
                  label="RM Name"
                  name="employee_name"
                  register={register}
                  required
                  requiredMessage="RM name is required"
                  error={errors.employee_name?.message}
                  disabled={getAppSelector.data.employee_name}
                />
              </Col>
              <Col sm={4}>
                <Input
                  label="Employee ID"
                  name="employee_id"
                  register={register}
                  required
                  requiredMessage="Employee ID is required"
                  error={errors.employee_id?.message}
                  disabled
                />
              </Col>
              <Col sm={4}>
                <DatePicker
                  label="Date"
                  name="submitted_date"
                  register={register}
                  required
                  requiredMessage="Date is required"
                  error={errors.submitted_date?.message}
                  disabled={getAppSelector.data.submitted_date}
                  isDateOfBirth
                />
              </Col>
            </Row>
            <Row>
              <Col md={20} className="mt-4" style={{ color: "#214382" }}>
                <h6>OPERATIONS MAKER</h6>
              </Col>
              <p>
                {/* I hereby certify that this application form is complete in all
                respects and relevant documents have been obtained as per extant
                KYC guidelines and performed due diligence to verify the
                genuineness of the customer. */}
                I hereby certify that this application form is complete in all
                respects and relevant documents have been obtained as per extant
                KYC guidelines and performed due diligence to verify the
                genuineness of the participant.
              </p>
            </Row>
            <Row className="justify-content-center">
              <Col sm={4} display={isMaker}>
                {/* <RmName
                  label="Rm Name"
                  name="maker_employee_name"
                  register={register}
                  required={useRole === "maker"}
                  requiredMessage="Maker RM name is required"
                  error={errors.maker_employee_name?.message}
                  disabled={useRole !== "maker"}
                /> */}
                <OpsMakerCheckerName
                  name={"maker_employee_name"}
                  label={"Maker Name"}
                  register={register}
                  disabled
                />
              </Col>
              <Col sm={4}>
                <Input
                  label="Employee ID"
                  name="maker_employee_id"
                  register={register}
                  required={useRole === "maker"}
                  requiredMessage="Maker Employee ID is required"
                  error={errors.maker_employee_id?.message}
                  disabled
                />
              </Col>
              <Col sm={4}>
                {/* <DatePicker
                  label="Date"
                  name="maker_submitted_date"
                  register={register}
                  // required={useRole === "maker"}
                  // requiredMessage="Date is required"
                  // error={errors.maker_submitted_date?.message}
                  disabled
                /> */}
                <DatePicker
                  label="Date"
                  name="maker_submitted_date"
                  register={register}
                  // required
                  // requiredMessage="Date is required"
                  // error={errors.maker_submitted_date?.message}
                  // disabled
                  isDateOfBirth
                  isOUDate
                />
              </Col>
            </Row>
            {useRole === "checker" && (
              <>
                <Row>
                  <Col md={20} className="mt-4" style={{ color: "#214382" }}>
                    <h6>OPERATIONS CHECKER</h6>
                  </Col>
                  <p>
                    I hereby certify that this application form is complete in
                    all respects and relevant documents have been obtained as
                    per extant KYC guidelines and performed due diligence to
                    verify the genuineness of the customer.
                  </p>
                </Row>
                <Row className="justify-content-center">
                  <Col sm={4} display={!isMaker}>
                    {/* <RmName
                  label="Rm Name"
                  name="checker_employee_name"
                  register={register}
                  required={useRole === "checker"}
                  requiredMessage="Checker RM Name is required"
                  error={errors.checker_employee_name?.message}
                  disabled={useRole === "maker"}
                /> */}
                    <OpsMakerCheckerName
                      name={"checker_employee_name"}
                      label={"Checker Name"}
                      register={register}
                      disabled
                      // required={useRole === "checker"}
                    />
                  </Col>
                  <Col sm={4}>
                    <Input
                      label="Employee ID"
                      name="checker_employee_id"
                      register={register}
                      // required={useRole === "checker"}
                      // requiredMessage="Checker Employee ID is required"
                      // error={errors.checker_employee_id?.message}
                      disabled
                    />
                  </Col>
                  <Col sm={4}>
                    <DatePicker
                      label="Date"
                      name="checker_submitted_date"
                      register={register}
                      // required={useRole === "checker"}
                      // requiredMessage="Date is required"
                      // error={errors.checker_submitted_date?.message}
                      // disabled
                      isOUDate
                    />
                  </Col>
                </Row>
              </>
            )}

            <Row>
              {(appStage === "Customer Registered" ||
                appStage === "In Customer Registration" ||
                appStage === "Expired") && (
                <Col className="text-start m-3">
                  <Button
                    label="Dashboard"
                    variant="primary"
                    onClick={() => {
                      navigate("/ops/dashboard");
                    }}
                  />
                </Col>
              )}

              <Col className="text-end m-3">
                {showSpinner ? (
                  <Spinner variant="primary" animation="border" />
                ) : (
                  <>
                    <Button
                      label={
                        useRole === "checker" ? "Authorise" : "Verify & Proceed"
                      }
                      type="submit"
                      disabled={
                        appStage === "In Customer Registration" ||
                        appStage === "Customer Registered" ||
                        appStage === "Expired"
                      }
                    ></Button>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
      <Row>
        <AuthoriseErrorModal
          showModal={showModal}
          onHide={() => setShowModal(false)}
          error={authoriseError}
        />
      </Row>
      <Row>
        <ValidationErrorModal
          showValidationModal={showValidationModal}
          onHide={() => setShowValidationModal(false)}
          error={validationError}
        />
      </Row>
      <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Document View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="text-center">
              {showModalSpinner ? (
                <Spinner variant="primary" animation="border" />
              ) : //
              fileType === "pdf" ? (
                <iframe
                  src={imageData}
                  title="Document viewer"
                  style={{ width: "400px", height: "300px" }}
                />
              ) : (
                <Image
                  src={imageData}
                  alt="Master Agreement is not yet Uploaded!"
                  style={{ width: "600px", height: "400px" }}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default OpsOfficeUsePage;
