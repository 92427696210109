import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
  Image,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import Button from "../../../../../components/common/Button";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import DueDiligenceDocType from "../../../../../components/common/dropdowns/DueDiligenceDocType";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { uploadAttachmentAction } from "../redux/action/UploadAttachmentAction";
import { createDueDiligenceAction } from "../redux/action/createDueDiligenceAction";
import { getAllDueDiligenceDocAction } from "../redux/action/getAllDueDiligenceDocAction";
import {
  MdCloudDownload,
  MdOutlineDeleteOutline,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { deleteDueDiligenceDocAction } from "../redux/action/deleteDueDiligenceDocAction";
import TextArea from "../../../../../components/common/Textarea";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import { VerificationStatus } from "../../../../constants/constants";
import { updateDueDiligenceDocAction } from "../redux/action/updateDueDiligenceDocAction";
import { getDueDiligenceDocAction } from "../redux/action/getDueDiligenceDocAction";
import { getSessionId, getUserRole } from "../../../../../utils/tokenHandlers";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import { downloadInstructions } from "../../../../apis/instructionsBtn/downloadInstructions";
import { downloadDueDiligenceDoc } from "../apis/downloadDueDiligenceDoc";
import { getAppAction } from "../redux/action/getAppAction";
import SectionHeading from "../../../../../components/common/SectionHeading";
import axios from "axios";

interface DueDiligenceType {
  file?: number[];
  sign_type?: string;
  customer_due_diligence_document_type?: string;
  customer_due_diligence_document_name?: string;
  varFile?: number[];
  maker_due_diligence_status?: string;
  maker_due_diligence_comments?: string;
  maker_due_diligence_attachment_ids?: number[];
  checker_due_diligence_status?: string;
  checker_due_diligence_comments?: string;
  checker_due_diligence_attachment_ids?: number[];
}

const OpsDueDilligence: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);
  const dispatch = useDispatch<AppDispatch>();
  const userRole = getUserRole();
  const navigate = useNavigate();

  const isMaker = userRole == "maker";

  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm<DueDiligenceType>({
    shouldUnregister: true,
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    watch: watch1,
    setError: setError1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm<DueDiligenceType>();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    setError: setError2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm<DueDiligenceType>();

  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteDueDiligenceDocId, setDeleteDueDiligenceDocId] = useState(0);

  const [selectedDocId, setSelectedDocId] = useState<number>(0);

  const getAllDueDiligenceDocs = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllDueDiligenceDoc.data
  );

  const createDueDiligenceDocs = useSelector(
    (state: RootState) => state.opsPpIndApp.createDueDiligence.data
  );

  const deleteDueDiligenceDocs = useSelector(
    (state: RootState) => state.opsPpIndApp.deleteDueDiligenceDoc.data
  );

  const updateDueDiligenceDocs = useSelector(
    (state: RootState) => state.opsPpIndApp.updateDueDiligenceDoc.data
  );

  const getDueDiligenceDocSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getDueDiligenceDoc.data
  );
  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  const appStage = state.data.stage;

  const [selectedRow, setSelectedRow] = useState(0);

  const handleRowClick = (index: any) => {
    setSelectedRow(index);
  };

  useEffect(() => {
    if (getAllDueDiligenceDocs.length > 0) {
      setSelectedDocId(getAllDueDiligenceDocs[0].id);
      setSelectedRow(getAllDueDiligenceDocs[0].id);
    }
  }, [getAllDueDiligenceDocs]);

  useEffect(() => {
    setValue1(
      "maker_due_diligence_status",
      getDueDiligenceDocSelector.maker_due_diligence_status
    );
    setValue1(
      "maker_due_diligence_comments",
      getDueDiligenceDocSelector.maker_due_diligence_comments
    );
    setValue2(
      "checker_due_diligence_status",
      getDueDiligenceDocSelector.checker_due_diligence_status
    );
    setValue2(
      "checker_due_diligence_comments",
      getDueDiligenceDocSelector.checker_due_diligence_comments
    );
  }, [getDueDiligenceDocSelector]);

  useEffect(() => {
    setTimeout(() => {
      setValue1(
        "maker_due_diligence_status",
        getDueDiligenceDocSelector.maker_due_diligence_status
      );
      setValue1(
        "maker_due_diligence_comments",
        getDueDiligenceDocSelector.maker_due_diligence_comments
      );
      setValue2(
        "checker_due_diligence_status",
        getDueDiligenceDocSelector.checker_due_diligence_status
      );
      setValue2(
        "checker_due_diligence_comments",
        getDueDiligenceDocSelector.checker_due_diligence_comments
      );
    }, 500);
  }, [getDueDiligenceDocSelector]);

  useEffect(() => {
    dispatch(getDueDiligenceDocAction(selectedDocId));
  }, [selectedDocId]);

  useEffect(() => {
    dispatch(getAllDueDiligenceDocAction(customerId));
  }, [createDueDiligenceDocs, deleteDueDiligenceDocs, updateDueDiligenceDocs]);

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  const handleDeleteDueDiligenceDoc = (deleteDueDiligenceDocId: any) => {
    dispatch(deleteDueDiligenceDocAction(deleteDueDiligenceDocId));
    setShowModal(false);
  };

  const checkAndNavigate = () => {
    dispatch(getAllDueDiligenceDocAction(customerId))
      .then((response) => {
        if (
          response.payload &&
          response.payload.result &&
          response.payload.result.result
        ) {
          const documents = response.payload.result.result;
          const totalRecords = response.payload?.result?.count;

          // Count total non-null/undefined maker statuses
          const makerStatusCount = documents.filter(
            (doc: { maker_due_diligence_status: string }) =>
              doc.maker_due_diligence_status
          ).length;

          // Count total non-null/undefined checker statuses
          const checkerStatusCount = documents.filter(
            (doc: { checker_due_diligence_status: string }) =>
              doc.checker_due_diligence_status
          ).length;

          // console.log("Total Maker Status Count:", makerStatusCount);
          // console.log("Total Checker Status Count:", checkerStatusCount);
          // console.log("Total:", totalRecords);
          if (userRole === "maker") {
            if (totalRecords === makerStatusCount) {
              navigate(`/ops/official-use-only/${customerId}`);
            } else {
              alert("Please update Status for all Documents.");
            }
          } else if (userRole === "checker") {
            if (totalRecords === checkerStatusCount) {
              navigate(`/ops/official-use-only/${customerId}`);
            } else {
              alert("Please update Status for all Documents.");
            }
          }
        } else {
          console.error("Invalid response format:", response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const [uploadSpinner, setUploadSpinner] = useState(false);
  const handleDueDiligenceDocSubmit = (data: any) => {
    // dispatchEvent
    // console.log(data);
    setUploadSpinner(true);

    dispatch(
      uploadAttachmentAction({
        fileName: data.file[0].name,
        file: data.file[0],
      })
    ).then((response) => {
      const dueDiligenceData = {
        customer_id: customerId,
        customer_due_diligence_document_type:
          data.customer_due_diligence_document_type,
        customer_due_diligence_document_name:
          data.customer_due_diligence_document_name,
        file_name: data.file[0].name,
        customer_due_diligence_attachment_ids: [
          response.payload.data.attachement,
        ],
      };
      dispatch(createDueDiligenceAction(dueDiligenceData)).then(
        (kycResponse) => {
          setUploadSpinner(false);
          reset();
          // console.log(kycResponse);
        }
      );
    });
  };

  const [showSpinner, setShowSpinner] = useState(false);

  const handleVerificationCommentSubmit = (data: any) => {
    // console.log(data);
    setShowSpinner(true);

    // dispatch(
    //   uploadAttachmentAction({
    //     fileName: data.varFile[0].name,
    //     file: data.varFile[0],
    //   })
    // ).then((response) => {
    //   // console.log(response);
    const dueDiligenceData: DueDiligenceType = {
      maker_due_diligence_status: data.maker_due_diligence_status,
      maker_due_diligence_comments: data.maker_due_diligence_comments,
      // maker_due_diligence_attachment_ids: [response.payload.data.attachement],
    };
    // console.log("VERI DATA", dueDiligenceData);
    dispatch(
      updateDueDiligenceDocAction({
        docId: selectedDocId,
        dueDiligenceDetails: dueDiligenceData,
      })
    ).then((kycResponse) => {
      // console.log(kycResponse);
      setShowSpinner(false);
      // navigate(`/ops/official-use-only/${customerId}`);
      dispatch(getDueDiligenceDocAction(selectedDocId));
      reset1();
    });
  };

  const handleCheckerVerificationCommentSubmit = (data: any) => {
    // console.log(data);
    setShowSpinner(true);
    // dispatch(
    //   uploadAttachmentAction({
    //     fileName: data.varFile[0].name,
    //     file: data.varFile[0],
    //   })
    // ).then((response) => {
    //   // console.log(response);
    const dueDiligenceData: DueDiligenceType = {
      checker_due_diligence_status: data.checker_due_diligence_status,
      checker_due_diligence_comments: data.checker_due_diligence_comments,
      // checker_due_diligence_attachment_ids: [
      //   response.payload.data.attachement,
      // ],
    };
    // console.log("VERI DATA", dueDiligenceData);
    dispatch(
      updateDueDiligenceDocAction({
        docId: selectedDocId,
        dueDiligenceDetails: dueDiligenceData,
      })
    ).then((kycResponse) => {
      // console.log(kycResponse);
      setShowSpinner(false);
      dispatch(getDueDiligenceDocAction(selectedDocId));
    });
  };

  const sessionId = getSessionId();
  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");
  const [modalSpinner, setModalSpinner] = useState(false);

  const handleDDDocView = async (docIds: number) => {
    setModalSpinner(true);
    handleShow();
    // console.log("Maker Due Diligence ID:", docIds);
    const docId = docIds;
    const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}?o_ses_id=${sessionId}`;
    try {
      const response = await axios.get<ArrayBuffer>(apiUrl, {
        responseType: "arraybuffer",
      });

      const dataAsArray = new Uint8Array(response.data);

      if (
        dataAsArray[0] === 0x25 &&
        dataAsArray[1] === 0x50 &&
        dataAsArray[2] === 0x44 &&
        dataAsArray[3] === 0x46
      ) {
        // const pdfBase64 = `data:application/pdf;base64,${btoa(
        //   String.fromCharCode.apply(null, Array.from(dataAsArray))
        // )}`;
        setImageData(apiUrl);
        // console.warn("its a pdf");
        setFileType("pdf");
        setModalSpinner(false);
      } else {
        const imageBase64 = `data:image/png;base64,${btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
        setImageData(imageBase64);
        // console.warn("its a img");
        setFileType("img");
        setModalSpinner(false);
      }

      setShowSpinner(false);
    } catch (error) {
      // console.error("Error fetching file:", error);
      setModalSpinner(false);
    }
    // Add your logic to open the document viewer or perform other actions
  };

  const handleFileChange = (e: any) => {
    const allowedExtensions = ["pdf", "jpeg", "png", "jpg"];

    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        // Display an error message or handle the invalid file type accordingly
        alert(
          "Invalid file type. Please select a PDF, JPEG, PNG, or JPG file."
        );
        e.target.value = ""; // Clear the file input
      }
    }
  };

  const handleUploadButtonClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    const fileInput = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (fileInput && !fileInput.files?.length) {
      alert("Please select a file before uploading.");
      return;
    } else {
      handleSubmit(handleDueDiligenceDocSubmit)();
    }
  };

  return (
    <>
      <NavbarVTX></NavbarVTX>
      <Header></Header>
      <Container>
        <Row>
          <Row>
            <Col
              md={20}
              className="text-center mt-4"
              style={{ color: "#214382" }}
            >
              <SectionHeading text="DUE DILIGENCE" />
            </Col>
          </Row>
          {userRole === "maker" &&
            (appStage === "In Maker Verification" ||
              appStage === "Sent back to maker") && (
              <Form style={{ width: "119%" }}>
                <Row style={{ marginTop: "30px" }}>
                  <Col sm={3} className="d-flex align-items-center">
                    <DueDiligenceDocType
                      label="Select Document "
                      name="customer_due_diligence_document_type"
                      register={register}
                      required
                      requiredMessage="Document Type is required"
                      error={
                        errors.customer_due_diligence_document_type?.message
                      }
                    />
                  </Col>
                  <Col sm={3} className="d-flex align-items-center">
                    <Input
                      label="Document Name"
                      name="customer_due_diligence_document_name"
                      register={register}
                      required
                      placeholder="Enter Document Name"
                      requiredMessage="Document Name is required"
                      error={
                        errors.customer_due_diligence_document_name?.message
                      }
                    />
                  </Col>
                  <Col sm={4} className="d-flex align-items-center">
                    <Form.Group controlId="formFileSm" className="mb-3">
                      <Form.Control
                        type="file"
                        size="sm"
                        accept="pdf, jpeg, png, jpg"
                        {...register("file")}
                        onChange={(e) => handleFileChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={2} className="d-flex align-items-center">
                    {uploadSpinner ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <Button
                        label="Upload & Save"
                        type="submit"
                        onClick={handleUploadButtonClick}
                        disabled={
                          appStage === "In Customer Registration" ||
                          appStage === "Customer Registered" ||
                          appStage === "Expired"
                        }
                      ></Button>
                    )}
                  </Col>
                </Row>
              </Form>
            )}

          <Table striped bordered size="sm" style={{ padding: "10%" }}>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Document Type</th>
                <th>Document Name</th>
                <th style={{ width: "30%" }}>File Name</th>
                {/* <th style={{ width: "12%" }}>Maker Supporting Documents</th>
                <th style={{ width: "12%" }}>Checker Supporting Documents</th> */}
                <th style={{ width: "10%" }}>Maker Status</th>
                <th style={{ width: "10%" }}>Checker Status</th>
                <th style={{ width: "10%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {getAllDueDiligenceDocs.length > 0 &&
                getAllDueDiligenceDocs.map((dueDiligenceDoc: any) => (
                  <tr
                    onClick={() => {
                      // console.log("Table row click", dueDiligenceDoc.id);
                      handleRowClick(dueDiligenceDoc.id);
                      setSelectedDocId(dueDiligenceDoc.id);
                    }}
                    style={{
                      backgroundColor:
                        selectedRow === dueDiligenceDoc.id ? "lightblue" : "",
                    }}
                  >
                    <td>
                      {dueDiligenceDoc.customer_due_diligence_document_type}
                    </td>
                    <td>
                      {dueDiligenceDoc.customer_due_diligence_document_name}
                    </td>
                    <td>{dueDiligenceDoc.file_name}</td>
                    <td>{dueDiligenceDoc.maker_due_diligence_status}</td>
                    <td>{dueDiligenceDoc.checker_due_diligence_status}</td>
                    {/* <td>
                      {dueDiligenceDoc.maker_due_diligence_attachment_ids
                        .length > 0 && (
                        <MdCloudDownload
                          style={{
                            width: "33px",
                            height: "24px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            downloadMakerAttachment(
                              dueDiligenceDoc
                                .maker_due_diligence_attachment_ids[0]
                            )
                          }
                        ></MdCloudDownload>
                      )}
                    </td> */}

                    {/* <td>
                      {dueDiligenceDoc.checker_due_diligence_attachment_ids
                        .length > 0 && (
                        <MdCloudDownload
                          style={{
                            width: "33px",
                            height: "24px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            downloadMakerAttachment(
                              dueDiligenceDoc
                                .checker_due_diligence_attachment_ids[0]
                            )
                          }
                        ></MdCloudDownload>
                      )}
                    </td> */}

                    <td style={{ color: "#214382" }}>
                      {(appStage === "In Maker Verification" ||
                        appStage === "In Checker Verification" ||
                        appStage === "Sent back to maker" ||
                        appStage === "In Customer Registration" ||
                        appStage === "Expired" ||
                        appStage === "Customer Registered") && (
                        <>
                          <MdOutlineRemoveRedEye
                            style={{
                              width: "33px",
                              height: "24px",
                              // color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleDDDocView(
                                dueDiligenceDoc
                                  .customer_due_diligence_attachment_ids[0]
                              )
                            }
                            //onClick={() => navigate("applicationlistview")}
                          />
                          {userRole === "maker" &&
                            (appStage === "In Maker Verification" ||
                              appStage === "Sent back to maker") && (
                              <MdOutlineDeleteOutline
                                style={{
                                  width: "33px",
                                  height: "24px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setDeleteDueDiligenceDocId(
                                    dueDiligenceDoc.id
                                  );
                                  setShowModal(true);
                                }}
                              ></MdOutlineDeleteOutline>
                            )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Row>
            <Col sm={6}>
              <Form onSubmit={handleSubmit1(handleVerificationCommentSubmit)}>
                <Row style={{ marginTop: "1px" }}>
                  <Col sm={12} className="d-flex align-items-center">
                    <Dropdown
                      label="Maker Verification Status"
                      name="maker_due_diligence_status"
                      register={register1}
                      options={VerificationStatus}
                      required
                      requiredMessage="Maker Verification is required"
                      error={errors.maker_due_diligence_status?.message}
                      // disabled={!isMaker}
                      disabled={
                        !isMaker ||
                        appStage === "In Customer Registration" ||
                        appStage === "Customer Registered" ||
                        appStage === "Expired"
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col sm={12} className="d-flex align-items-center">
                    <TextArea
                      label="Verification Remarks"
                      name="maker_due_diligence_comments"
                      register={register1}
                      placeholder="Enter Verification Remarks"
                      required
                      requiredMessage="Address is required"
                      // error={errors.maker_due_diligence_comments?.message}
                      disabled={
                        !isMaker ||
                        appStage === "In Customer Registration" ||
                        appStage === "Customer Registered" ||
                        appStage === "Expired"
                      }
                    />
                  </Col>
                </Row>
                <DisplayBox display={isMaker}>
                  <Form.Group controlId="formFile">
                    <Row style={{ marginTop: "10px" }}>
                      {/* <Col sm={4}>
                        <Form.Label className="float-end">
                          <span>
                            Supporting Docs
                            <span className="text-danger form-label">*</span>:
                          </span>
                        </Form.Label>
                      </Col> */}
                      {/* <Col sm={8} className="d-flex align-items-center">
                        <Form.Control type="file" {...register1("varFile")} />
                      </Col> */}
                    </Row>
                  </Form.Group>
                  <Row>
                    <Col md={20} className="text-end">
                      {showSpinner ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        <Button
                          label="Save Details"
                          variant="outline-primary"
                          type="submit"
                          disabled={
                            getAllDueDiligenceDocs.length == 0 ||
                            appStage === "In Customer Registration" ||
                            appStage === "Customer Registered" ||
                            appStage === "Expired"
                          }
                        ></Button>
                      )}
                    </Col>
                  </Row>
                </DisplayBox>
              </Form>
            </Col>
            <DisplayBox display={!isMaker}>
              <Col sm={6}>
                <Form
                  onSubmit={handleSubmit2(
                    handleCheckerVerificationCommentSubmit
                  )}
                >
                  <Row style={{ marginTop: "1px" }}>
                    <Col sm={12} className="d-flex align-items-center">
                      <Dropdown
                        label="Checker Verification Status"
                        name="checker_due_diligence_status"
                        register={register2}
                        options={VerificationStatus}
                        required
                        requiredMessage="Checker Verification is required"
                        disabled={
                          appStage === "In Customer Registration" ||
                          appStage === "Customer Registered" ||
                          appStage === "Expired"
                        }
                        // error={errors.checker_due_diligence_status?.message}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col sm={12} className="d-flex align-items-center">
                      <TextArea
                        label="Verification Remarks"
                        name="checker_due_diligence_comments"
                        register={register2}
                        placeholder="Enter Verification Remarks"
                        required
                        requiredMessage="Verification Remarks is required"
                        disabled={
                          appStage === "In Customer Registration" ||
                          appStage === "Customer Registered" ||
                          appStage === "Expired"
                        }
                        // error={errors.checker_due_diligence_comments?.message}
                      />
                    </Col>
                  </Row>
                  {/* <Form.Group controlId="formFile">
                    <Row style={{ marginTop: "10px" }}>
                      <Col sm={4}>
                        <Form.Label className="float-end">
                          <span>
                            Souporting Docs
                            <span className="text-danger form-label">*</span>:
                          </span>
                        </Form.Label>
                      </Col>
                      <Col sm={8} className="d-flex align-items-center">
                        <Form.Control type="file" {...register2("varFile")} />
                      </Col>
                    </Row>
                  </Form.Group> */}
                  <Row>
                    <Col md={20} className="text-end">
                      {showSpinner ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        <Button
                          label="Save Details"
                          type="submit"
                          variant="outline-primary"
                          disabled={
                            getAllDueDiligenceDocs.length == 0 ||
                            appStage === "In Customer Registration" ||
                            appStage === "Customer Registered" ||
                            appStage === "Expired"
                          }
                        ></Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </DisplayBox>
          </Row>
        </Row>
        <Row>
          <Col md={20} className="text-end">
            {userRole === "maker" &&
              getDueDiligenceDocSelector.maker_due_diligence_status && (
                <Button
                  label="Save & Next"
                  onClick={() => {
                    checkAndNavigate();
                    // navigate(`/ops/official-use-only/${customerId}`);
                  }}
                  disabled={getAllDueDiligenceDocs.length == 0}
                ></Button>
              )}
            {userRole === "checker" &&
              getDueDiligenceDocSelector.checker_due_diligence_status && (
                <Button
                  label="Save & Next"
                  onClick={() => {
                    checkAndNavigate();
                    // navigate(`/ops/official-use-only/${customerId}`);
                  }}
                  disabled={getAllDueDiligenceDocs.length == 0}
                ></Button>
              )}
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Document?</Modal.Body>
        <Modal.Footer>
          <Button label="Cancel" onClick={() => setShowModal(false)} />
          <Button
            label="Delete"
            onClick={() => handleDeleteDueDiligenceDoc(deleteDueDiligenceDocId)}
          />
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Due Diligence Document View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="text-center">
              {modalSpinner ? (
                <Spinner variant="primary" animation="border" />
              ) : //
              fileType === "pdf" ? (
                <iframe
                  src={imageData}
                  title="Document viewer"
                  style={{ width: "400px", height: "300px" }}
                />
              ) : (
                <Image
                  src={imageData}
                  style={{ width: "600px", height: "400px" }}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OpsDueDilligence;
