import React, { useEffect, useState } from "react";
import NavbarVTX from "../../../../../components/common/Navbar";
import Header from "../../../../../components/common/Header";
import Input from "../../../../../components/common/Input";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import CountryDropdown from "../../../../../components/common/CountryDropdown";
import DatePicker from "../../../../../components/common/DatePicker";
import Button from "../../../../../components/common/Button";
import CheckBoxes from "../../../../../components/common/CheckBoxes";
// import "./AddKeyIndividual.css";
import { useNavigate, useParams } from "react-router-dom";
import IndividualDesignation from "../../../../../components/common/dropdowns/IndividualDesignation";
import Gender from "../../../../../components/common/dropdowns/Gender";
import { KeyPersonDetailsType } from "./types/KeyPersonDetailsType";
import KeyPersonType from "../../../../../components/common/KeyPersonType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { createKeyPersonAction } from "../redux/action/createKeyPersonAction";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import {
  ADDRESS_REGEX,
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  CKYC_REGEX,
  EMAIL_REGEX,
  NON_INDIAN_REGEX,
  OWNERSHIP_REGEX,
  PAN_REGEX,
  PARTNERSHIP_REGEX,
  PHONE_REGEX,
  PINCODE_REGEX,
} from "../../../../constants/regexPatterns";
import SectionHeading from "../../../../../components/common/SectionHeading";
import IsdCode from "../../../../../components/common/dropdowns/IsdCode";
import PhoneInputWithDropdown from "../../../../../components/common/PhoneInput";
import PassportExpiryDatePicker from "../../../../../components/common/PassportExpiryDatePicker";
import SingleCheckBox from "../../../../../components/common/SingleCheckbox";
import { getAllKeyPersonAction } from "../redux/action/getAllKeyPersonAction";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";

const OpsAddKeyPersonDetailsPage: React.FC = () => {
  let { appId } = useParams();
  let customerId = Number(appId);

  const dispatch = useDispatch<AppDispatch>();

  const keyPersonsSelector: KeyPersonDetailsType[] = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllKeyPerson.data
  );

  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getAllKeyPersonAction(customerId));
  }, [customerId, dispatch]);

  useEffect(() => {
    // Extract email addresses from keyPersonsSelector and store them in the state
    const extractedEmails = keyPersonsSelector
      .map((person) => person.email)
      .filter((email) => email); // Filtering out null or undefined emails
    setEmails(extractedEmails);
  }, [keyPersonsSelector]);

  // useEffect(() => {
  //   if (emails) {
  //     // console.warn(emails);
  //   }
  // }, []);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<KeyPersonDetailsType>({
    shouldUnregister: true,
  });

  const isNationalityIndia = Number(watch("nationality_country_id")) === 239;

  const isCountryIndia = Number(watch("key_person_address_country_id")) === 239;
  const navigate = useNavigate();

  const [selectedKeyPersonType, setSelectedKeyPersonType] = useState<number[]>(
    []
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const dob = watch("date_of_birth");
  const [dobOnchange, setDOB] = useState("");
  const [isMinorDOB, setIsMinorDOB] = useState(false);

  const isBeneficialOwner = selectedKeyPersonType.includes(573);
  const isEntityAdmin = !selectedKeyPersonType.includes(575);
  const entityAdmin = selectedKeyPersonType.includes(575);
  const isMinor = watch("is_minor");
  const isPartner = watch("designation") == "partner";
  const passportExp = watch("passport_expiry_date");
  const designation = watch("designation");
  const isdCode = watch("phone_country_code");
  const phone = watch("phone");
  const email = watch("email");
  const handleKeyPersonDetailsSubmit: SubmitHandler<KeyPersonDetailsType> = (
    keyPersonDetails
  ) => {
    // setShowEmailError(false);
    // if (entityAdmin) {
    //   if (emails.includes(email)) {
    //     // console.error("Error: Email already exists");
    //     setShowEmailError(true);
    //     setTimeout(() => {
    //       setShowEmailError(false);
    //     }, 2000);
    //   }
    //   return;
    // }
    setShowSpinner(true);
    // // console.log("hello");
    keyPersonDetails.customer_id = customerId;
    keyPersonDetails.key_person_type_ids = selectedKeyPersonType;
    keyPersonDetails.receive_otp_by = "email";
    keyPersonDetails.key_person_address_country_id = Number(
      keyPersonDetails.key_person_address_country_id
    );
    keyPersonDetails.nationality_country_id = Number(
      keyPersonDetails.nationality_country_id
    );
    if (!passportExp) {
      keyPersonDetails.passport_expiry_date = false;
    } else {
      keyPersonDetails.passport_expiry_date = passportExp;
    }
    // console.log(keyPersonDetails, "hello");
    if (isdCode === "+91" && phone?.length !== 10) {
      alert("Please enter 10 digit phone number");
      setShowSpinner(false);
    } else {
      dispatch(createKeyPersonAction(keyPersonDetails)).then((response) => {
        try {
          if (response.payload.result) {
            navigate(`/key-person-details/${customerId}`);
            // console.log("response--", response.payload);
            setShowSpinner(false);
          } else {
            alert("Something went wrong, Please contact admin!");
            setShowSpinner(false);
          }
        } catch (err) {
          alert("Something went wrong, Please contact admin!");
          setShowSpinner(false);
        }
      });
    }
  };

  const handleDateChange = (selectedDate: string) => {
    setDOB(selectedDate);
    // console.log(selectedDate);
    const currentDate = new Date();
    const birthDate = new Date(selectedDate);
    const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
    const isUnder18 =
      ageDiff < 18 ||
      (ageDiff === 18 && currentDate.getMonth() < birthDate.getMonth());

    // console.log("Is under 18:", isUnder18);
    if (isUnder18 === true) {
      setIsMinorDOB(true);
      setValue("is_minor", true);
    } else {
      setIsMinorDOB(false);
      setValue("is_minor", false);
    }
    // Add any additional logic you want to perform on date change
  };

  useEffect(() => {
    if (dob) {
      const currentDate = new Date();
      const birthDate = new Date(dob);
      const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
      const isUnder18 =
        ageDiff < 18 ||
        (ageDiff === 18 && currentDate.getMonth() < birthDate.getMonth());

      // console.log("Is under 18:", isUnder18);
      if (isUnder18 === true) {
        setIsMinorDOB(true);
        setValue("is_minor", true);
      } else if (isUnder18 === false) {
        setIsMinorDOB(false);
      }
    }
  }, [dob, setValue]);

  return (
    <>
      <DisplayBox display={showEmailError}>
        <AlertPopup
          message="An Entity Admin with this Email already exists"
          variant="danger"
        ></AlertPopup>
      </DisplayBox>
      <NavbarVTX />
      <Header />
      <Container>
        <Row>
          <Col md={20} className="myCol">
            <SectionHeading text="ADD KEY PERSON DETAILS" />
          </Col>
        </Row>

        <Form onSubmit={handleSubmit(handleKeyPersonDetailsSubmit)}>
          <Row className="myrow">
            <Col md={5} style={{ marginLeft: "30%" }}>
              <KeyPersonType
                name="key_person_type_ids"
                label=""
                register={register}
                required
                // requiredMessage="Key Person Is required"
                error={errors.key_person_type_ids?.message}
                selectedKeyPersonType={selectedKeyPersonType}
                setSelectedKeyPersonType={setSelectedKeyPersonType}
                setValue={setValue}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <IndividualDesignation
                label="Designation"
                name="designation"
                register={register}
                required
                requiredMessage="Designation is required"
                error={errors.designation?.message}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Nationality"
                name="nationality_country_id"
                register={register}
                required
                // disabled
                requiredMessage="Nationality is required"
                error={errors.nationality_country_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 1"
                // regexPattern={ADDRESS_REGEX}
                name="key_person_address_line_1"
                register={register}
                placeholder="Enter Address line 1"
                required
                requiredMessage="Address line 1 is required"
                error={errors.key_person_address_line_1?.message}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <DisplayBox display={designation === "others"}>
                <Input
                  label={"If Others,"}
                  name={"if_others"}
                  register={register}
                  required={designation === "others"}
                  placeholder="Designation if others"
                  error={errors.if_others?.message}
                  regexPattern={ALPHABETS_REGEX}
                />
              </DisplayBox>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="First name"
                name="first_name"
                regexPattern={ALPHABETS_REGEX}
                register={register}
                placeholder="Enter first name"
                required
                requiredMessage="First Name is required"
                error={errors.first_name?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="PAN/TAX Id"
                name="tax_id"
                regexPattern={isNationalityIndia ? PAN_REGEX : undefined}
                regexMessage={isNationalityIndia ? "Invalid PAN" : ""}
                register={register}
                placeholder="Enter Pan/ Tax Id"
                required
                requiredMessage="Tax Id is required"
                error={errors.tax_id?.message}
                maxLength={isNationalityIndia ? 10 : 20}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Address Line 2"
                // regexPattern={ADDRESS_REGEX}
                name="key_person_address_line_2"
                register={register}
                placeholder="Enter Address line 2"
                required
                requiredMessage="Address line 2 is required"
                error={errors.key_person_address_line_2?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Middle name"
                regexPattern={ALPHABETS_REGEX}
                name="middle_name"
                register={register}
                placeholder="Enter middle name"
                // required
                // requiredMessage="middle name is required"
                error={errors.middle_name?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Passport No"
                name="passport_number"
                regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                placeholder="Enter passport no"
                // required
                // requiredMessage="passport number is required"
                error={errors.passport_number?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="City/Town/Village"
                name="key_person_address_city"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter city/town/village"
                required
                requiredMessage="City is required"
                error={errors.key_person_address_city?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Last name"
                name="last_name"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter last name"
                required
                requiredMessage="Last Name is required"
                error={errors.last_name?.message}
              />
            </Col>
            <Col sm={4}>
              {/* <DatePicker
                label="Passport Expiry"
                name="passport_expiry_date"
                register={register}
                isPassportExpiry={true}
                // required
                // requiredMessage="passport expiry date is required"
                error={errors.passport_expiry_date?.message}
              /> */}
              <PassportExpiryDatePicker
                label="Passport Expiry"
                name="passport_expiry_date"
                register={register}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="District/City"
                name="key_person_address_district"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter district/city"
                required
                requiredMessage="District is required"
                error={errors.key_person_address_district?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              {/* <Input
                label="Mother name"
                name="mother_name"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter mother name"
                required
                requiredMessage="Mother Name is required"
                error={errors.mother_name?.message}
              /> */}
              <DatePicker
                label="Date of Birth"
                name="date_of_birth"
                register={register}
                isDateOfBirth
                required
                requiredMessage="Date of Birth is required"
                error={errors.date_of_birth?.message}
                onChange={handleDateChange}
                skipCurrentYear
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Employee Id"
                name="employee_id"
                regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                placeholder="Enter employee id"
                // required
                // requiredMessage="employee id is required"
                error={errors.employee_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="State/County"
                name="key_person_address_state"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter state/country"
                required
                requiredMessage="State is required"
                error={errors.key_person_address_state?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Gender
                label="Gender"
                name="gender"
                register={register}
                // required
                // requiredMessage="gender is required"
                error={errors.gender?.message}
              />
            </Col>
            <Col sm={4}>
              <PhoneInputWithDropdown
                label={entityAdmin ? "Cellphone" : "Contact no/ Landline"}
                name="phone"
                register={register}
                isdCodeName={"phone_country_code"}
                isdCodeRegister={register}
                placeholder="Enter your Contact no"
                error={errors.phone?.message}
                required
                regexPattern={PHONE_REGEX}
                // inputDefaultValue={`${localStorage.getItem("guestMobile")}`}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Country"
                name="key_person_address_country_id"
                register={register}
                required
                requiredMessage="Country is required"
                error={errors.key_person_address_country_id?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              {/* <DatePicker
                label="Date of Birth"
                name="date_of_birth"
                register={register}
                isDateOfBirth
                required
                requiredMessage="Date of Birth is required"
                error={errors.date_of_birth?.message}
                onChange={handleDateChange}
              /> */}
              <Input
                label="Mother name"
                name="mother_name"
                register={register}
                regexPattern={ALPHABETS_REGEX}
                placeholder="Enter mother name"
                required
                requiredMessage="Mother Name is required"
                error={errors.mother_name?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="CKYC No"
                name="ckycno"
                regexPattern={CKYC_REGEX}
                register={register}
                placeholder="Enter ckyc"
                // required
                // requiredMessage="ckycno is required"
                error={errors.ckycno?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Pincode"
                name="key_person_address_pincode"
                register={register}
                regexPattern={isCountryIndia ? PINCODE_REGEX : NON_INDIAN_REGEX}
                regexMessage="Please enter correct pincode"
                placeholder="Enter pincode"
                required
                requiredMessage="Pincode is required"
                error={errors.key_person_address_pincode?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="Ownership %"
                name="ownership_percentage"
                register={register}
                placeholder="Enter ownership %"
                required={isBeneficialOwner}
                regexPattern={OWNERSHIP_REGEX}
                regexMessage="Value must be a number less than 100"
                // requiredMessage="ownership % is required"
                error={errors.ownership_percentage?.message}
              />
            </Col>
            <Col sm={4}>
              <CountryDropdown
                label="Tax residency"
                name="tax_residency_country_id"
                register={register}
                //placeholder="Enter tax residency"
                required
                requiredMessage="Tax Residency is required"
                error={errors.tax_residency_country_id?.message}
              />
            </Col>
            <Col sm={4}>
              <Input
                label="Email"
                name="email"
                register={register}
                regexPattern={EMAIL_REGEX}
                placeholder="Enter email"
                required
                requiredMessage="Email is required"
                error={errors.email?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <Input
                label="DIN/DPIN"
                name="din"
                regexPattern={ALPHANUMERIC_REGEX}
                register={register}
                placeholder="Enter DIN/DPIN"
                // required
                // requiredMessage="din is required"
                error={errors.din?.message}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={4}>
              <DisplayBox display={isBeneficialOwner || isMinorDOB}>
                {/* <CheckBoxes
                  label="Is minor  ?"
                  name="is_minor"
                  register={register}
                  //required
                  error={errors.is_minor?.message}
                /> */}
                <SingleCheckBox
                  name={"is_minor"}
                  label={"Is Minor ?"}
                  register={register}
                  required={isMinorDOB}
                />
              </DisplayBox>
            </Col>
            <Col sm={4}>
              <DisplayBox display={isMinor}>
                <Input
                  label="Guardian Name"
                  name="guardian_name"
                  regexPattern={ALPHABETS_REGEX}
                  register={register}
                  placeholder="Enter guardian name"
                  required
                  requiredMessage="Guardian Name is required"
                  error={errors.guardian_name?.message}
                />
              </DisplayBox>
            </Col>

            <Col sm={4}>
              <DisplayBox display={isMinor}>
                <Input
                  label="Guardian Relationship"
                  name="guardian_relation"
                  regexPattern={ALPHABETS_REGEX}
                  register={register}
                  placeholder="Enter guardian relationship"
                  required
                  requiredMessage="Guardian Relation is required"
                  error={errors.guardian_relation?.message}
                />
              </DisplayBox>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={4}>
              <DisplayBox display={isEntityAdmin}>
                {/* <CheckBoxes
                  label="Is Politically exposed person  ?"
                  name="is_politically_exposed"
                  register={register}
                  //required
                  // error={errors.is_politically_exposed?.message}
                /> */}
                <SingleCheckBox
                  name={"is_politically_exposed"}
                  label={"Is Politically exposed person ?"}
                  register={register}
                />
              </DisplayBox>
            </Col>
            <Col sm={4}>
              <DisplayBox display={isPartner}>
                <Input
                  label="Partnership(%)"
                  name="partnership_percentage"
                  register={register}
                  placeholder="Partnarship(%)"
                  regexPattern={PARTNERSHIP_REGEX}
                  regexMessage="Value must be a number less than 100"
                  error={errors.partnership_percentage?.message}
                />
              </DisplayBox>
            </Col>
            <Col sm={4}></Col>
          </Row>
          <Row>
            <Col md={20} className="text-end">
              {showSpinner ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Button
                  label="Save Details"
                  type="submit"
                  // onClick={handleSubmit(handleKeyPersonDetailsSubmit)}
                ></Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default OpsAddKeyPersonDetailsPage;
