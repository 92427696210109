import axios from "axios";
export const fetchHeaderRecord = (
  customerId: number,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/one/tradexwbench.customer/${customerId}`,
      {
        params: {
          filter: "[]",
          query:
            "{stage,application_uid, customer_contact_name, customer_contact_mobile, customer_contact_email, business_participant_type, business_registration_country_id{name}, business_gst, business_tanno,business_name,  business_constitution_type, is_counterparty, customer_contact_mobile_country_code, business_tax_identification_number}",
        },
      },
      {
        params: {
          o_ses_id: sessionId,
        },
      }
    )
    .then((response) => {
      // // console.warn("in api call", response);
      return response;
    })
    .catch((err) => err);
};
