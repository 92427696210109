import axios from "axios";
import { getSessionId } from "../../../../utils/tokenHandlers";

export const deleteDocumentRecord = (docId: number) => {
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/delete/many/tradexwbench.customer_kyc_document/`,
      {
        params: {
          filter: [["attachment_ids", "=", docId]],
        },
      },
      {
        params: {
          o_ses_id: sessionId,
        },
      }
    )
    .then((response) => {
      //  // console.warn(response, "res from api call");
      return response;
    })
    .catch((err) => err);
};
