import axios from "axios";
import { BankDetailsType } from "../pages/types/BankDetailsType";

export const createLocalBank = (
  bankDetails: BankDetailsType,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/create/one/tradexwbench.customer_settlement_bank`,
      {
        params: {
          context: {},
          data: bankDetails,
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
