import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";
import { SupportingDocsType } from "../redux/types/VerificationDetailsType";

export const updateSupportingDocStatus = async (
  docId: number,
  data: SupportingDocsType
) => {
  const sessionid = getSessionId();

  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/update/one/tradexwbench.customer_kyc_document/${docId}`,
      {
        params: {
          context: {},
          data: data,
        },
      },
      {
        params: {
          o_ses_id: sessionid,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
