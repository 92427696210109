import axios from "axios";
// import { activeRmDepartmentNames } from "../../constants/changeConstants";
import { getSessionId } from "../../../../../utils/tokenHandlers";

interface ResultArray {
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

interface ResultBody {
  count: number;
  prev: null;
  current: number;
  next: number;
  total_pages: number;
  result: Array<ResultArray>;
}

interface ResponseBody {
  jsonrpc: string;
  id: null;
  result: ResultBody;
}

export const getOpsEmpID = (userID: number | null) => {
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/hr.employee`,
      {
        params: {
          filter: `[('user_id','=',${userID})]`,
          all_pages: true,
          query:
            "{id,name,user_id,user_partner_id,active,company_id,company_country_id,company_country_code,barcode, department_id{name,id}}",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response): ResponseBody => response.data)
    .catch((err) => err);
};
