import axios from "axios";

interface ResultArray {
  id: number;
  stage: string;
  business_name: string;
  application_uid: string;
}

interface Result {
  count: number;
  prev: null;
  current: number;
  next: number;
  total_pages: number;
  result: Array<ResultArray>;
}

interface AllParticipantsResponse {
  jsonrpc: string;
  id: null;
  result: Result;
}

export const getReferredBy = (sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer`,
      {
        params: {
          filter:
            "[('is_counterparty','!=','true'), ('stage','=','Customer Registered'), ('business_participant_type','!=','Financier')]",
          all_pages: "true",
          order: "create_date desc",
          query: "{id, stage,application_uid, business_name}",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response): AllParticipantsResponse => response.data)
    .catch((err) => err);
};
