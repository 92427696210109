import axios from "axios";
import { UpdateAppData } from "../redux/types/UpdateAppType";
import { BusinessDetailsType } from "../pages/types/BusinessDetailsType";
import { getSessionId } from "../../../../../utils/tokenHandlers";

export const deleteCustomerDocument = (
  customerId: number,
  customerDetails: { [key: number]: any }
) => {
  const sessionId = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/update/one/tradexwbench.customer/${customerId}`,
      {
        params: {
          context: {},
          data: customerDetails,
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      // console.log(response);
      return response;
    })
    .catch((err) => err);
};
