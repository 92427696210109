import axios from "axios";

export const getGstAddress = (gstNumber: string, sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.temp_gst_details`,
      {
        params: {
          filter: `[('gstin', '=',  '${gstNumber}')]`,
          query: "{id,bnm,st,bno,loc,stcd,pncd}",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
