import axios from "axios";

interface ResultArray {
  sr_no: number;
  lov_type: string;
  lov_display_value: string;
  lov_value: string;
  is_active: boolean;
}

interface ResultBody {
  count: number;
  prev: null;
  current: number;
  next: number;
  total_pages: number;
  result: Array<ResultArray>;
}

interface ResponseBody {
  jsonrpc: string;
  id: null;
  result: ResultBody;
}

export const getSubIndustryType = (
  industryType: string,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.list_of_value_master`,
      {
        params: {
          filter: `[('parent_industry','=', '${industryType}')]`,
          all_pages: true,
          query:
            "{id,sr_no,lov_type,lov_display_value,lov_value,is_active, parent_industry}",
          order: "sr_no asc",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response): ResponseBody => response.data)
    .catch((err) => err);
};
