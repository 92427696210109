import axios from "axios";
import { CreateAddressType } from "../pages/types/CreateAddressType";
import { CorrBankType } from "../../../../guestUser/common/types/GuestCorrBankDetails";

export const updateCorrBank = (
  corrBankId: number,
  corrBankDetails: CorrBankType,
  sessionId: string | null
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/update/one/tradexwbench.customer_settlement_correspondent_bank/${corrBankId}`,
      {
        params: {
          context: {},
          data: corrBankDetails,
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
