// apis/uploadFile.js

import axios from "axios";
import { getSessionId } from "../../../../utils/tokenHandlers";

export const uploadFile = async (
  docName: string | Blob,
  image: string | Blob
) => {
  const sessionid = getSessionId();
  const formData = new FormData();

  if (docName) {
    formData.append("name", docName);
  }

  formData.append("type", "binary");
  formData.append("datas", image);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/upload/image`,
      formData,
      {
        params: {
          o_ses_id: sessionid,
        },
      }
    );

    const { data } = response;

    if (data) {
      const { message, data: responseData } = data;
      if (responseData) {
        const { attachement } = responseData; // Corrected the variable name here
        //  // console.warn("Message-->", message);
        //  // console.warn("Attachment==>", attachement);
        localStorage.setItem("attachmentid", attachement);
        //  // console.warn(sessionid);

        // TODO: use the second API here
      }
      if (message === "Success") {
        //  // console.log(message);
        //  // console.log(localStorage.getItem("attachmentid"));
        // handleUploadKYC();
      }
    }
    //  // console.warn("in api file-->", response);

    return response.data; // Return the response data if needed
  } catch (error) {
    // // console.error("An error occurred", error);
    throw error; // Throw the error for handling in Redux action
  }
};
