import axios from "axios";

export const deleteAddress = (addressId: number, sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/delete/one/tradexwbench.customer_address/${addressId}`,
      {},
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
