import React from "react";
import VerBusinessDetails from "../../../components/common/VerBusinessDetails";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const IndiaBasicDetails: React.FC = () => {
  const state = useSelector(
    (state: RootState) => state.verification.verBusinessDetails
  );
  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }
  const basicDetailsStore = state.data.data.result;

  return (
    <>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Participation Role"
            defaultValue={basicDetailsStore.business_participant_type}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="TAN"
            defaultValue={basicDetailsStore.business_tanno}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="GSTIN"
            defaultValue={basicDetailsStore.business_gst}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="PAN"
            defaultValue={basicDetailsStore.business_pan}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Business Name"
            defaultValue={basicDetailsStore.business_name}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Constitution Type"
            defaultValue={
              basicDetailsStore.business_constitution_type === "others"
                ? basicDetailsStore.business_constitution_type_other
                : basicDetailsStore.business_constitution_type
            }
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="TAN Issued By"
            defaultValue={basicDetailsStore.business_tanno_issuing_authority}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Designation"
            defaultValue={
              basicDetailsStore.designation === "test"
                ? "NA"
                : basicDetailsStore.designation
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default IndiaBasicDetails;
