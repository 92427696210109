import { getSessionId } from "../../../utils/tokenHandlers";
import axios from "axios";

export const fetchLocalBank = async (customerId: number) => {
  const sessionId = getSessionId();

  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer_settlement_bank/`,
      {
        params: {
          filter: `[('customer_id', 'in',  [${customerId}])]`,
          query:
            "{id, is_default_bank,customer_id,bank_name,branch_name,bank_account_name,bank_account_number,re_enter_bank_account_number,ifsc_code,iban,swift_code,account_type,bank_official_name,bank_official_email,phone_country_code,bank_official_phone,bank_official_mobile,undertaking_confirm,bank_address_line_1,bank_address_line_2,bank_address_city,bank_address_district,bank_address_state,bank_address_pincode,bank_address_country_id{name},customer_settlement_correspondent_bank_ids,currency_id{currency_code}, other_account_type}",
        },
      },
      {
        params: {
          o_ses_id: sessionId,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
