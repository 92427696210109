import axios from "axios";

interface ResultArray {
  id: number;
  name: string;
  code: string;
  isd_code: string;
  is_active: boolean;
}

interface Result {
  count: number;
  prev: null;
  current: number;
  next: number;
  total_pages: number;
  result: Array<ResultArray>;
}

interface AllCurrenciesResponse {
  jsonrpc: string;
  id: null;
  result: Result;
}

export const getLocalBankCurrencies = (sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.currency_master`,
      {
        params: {
          filter: "[('master_status', '=' , 'Active')]",
          query:
            "{id,sr_no,currency_name,currency_code,master_status,transaction_status}",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response): AllCurrenciesResponse => response.data)
    .catch((err) => err);
};
