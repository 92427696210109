/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Row, Col, Button, Spinner, Modal } from "react-bootstrap";
import { MdCheck, MdEdit } from "react-icons/md";
import Input from "../../../components/common/Input";
import { GuestAppAccessType } from "../redux/types/GuestLoginType";
import SectionHeading from "../../../components/common/SectionHeading";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { sendAppAccessOTP } from "../redux/action/sendAppAccessOTP";
import { appAccessOTPVerify } from "../redux/action/appAccessOTPVerify";
import { doLogin } from "../../../utils/tokenHandlers";
import { DisplayBox } from "../../../components/containers/DisplayBox";
import AlertPopup from "../../../components/common/Alert/AlertPopup";

const ApplicationAccess: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showChkSpinner, setShowChkSpinner] = useState(false);
  const [ARNNo, setARNNo] = useState("");
  const [otpValue, setOTPValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const [arnTimer, setArnTimer] = useState(59);
  const [showValidARNAlert, setShowValidARNAlert] = useState(false);
  const [, setArnCheckIconClicked] = useState(false);
  const [showARNOtpField, setShowARNOtpField] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [OTPfromResult, setOTPfromResult] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<GuestAppAccessType>();

  const watchedFieldsARN = {
    guest_user_arn: ARNNo,
    guest_user_otp: otpValue,
  };

  useEffect(() => {
    if (showValidARNAlert) {
      setTimeout(() => {
        setShowValidARNAlert(false);
      }, 2000);
    }
  });

  const handleEditIconClick = async () => {
    if (emailFieldDisabled) {
      setArnTimer(59);
      setShowARNOtpField(false);
      setCheckIconClicked(false);
      setCheckIconDisabled(false);
      setEmailFieldDisabled(false);
    }
  };

  const onSubmitApplicationAccess: SubmitHandler<GuestAppAccessType> = async (
    data
  ) => {
    // // console.log("Application Access:", data);
    if (!otpValue) {
      alert("Click on the check-mark to get the OTP");
    } else {
      setShowSpinner(true);
      try {
        const result = await dispatch(appAccessOTPVerify(watchedFieldsARN));
        if (result.payload) {
          //  // console.warn("hello main bro", result.payload);
          doLogin(result.payload.data, () => {
            if (result.payload.data) {
              const appId = result.payload.data.result.arn_id;
              //  // console.warn(appId);
              const constType = result.payload.data.result.constitution_type;
              const stage = result.payload.data.result.stage;
              if (appId && constType && stage) {
                checkAndNavigate(appId, constType, stage);
                // // console.log("je;lo");
              } else {
                alert(result.payload.data.result.message);
                setShowSpinner(false);
              }

              // if (appId) {
              //   navigate(
              //     `/guest/india/non-corporate/business-details/${appId}`
              //   );
              // } else {
              //   alert("Something went wrong, Please contact admin");
              //   setShowSpinner(false);
              // }
            } else {
              alert("Please enter correct OTP");
              setShowSpinner(false);
            }
          });
        }
      } catch (error) {
        // // console.warn("hello", error);
      }
    }
  };

  const checkAndNavigate = async (
    appId: number,
    constType: string,
    stage: string
  ) => {
    let arn = watchedFieldsARN.guest_user_arn;
    let countryCode = arn.substring(4, 6);
    let role = arn.substring(0, 2);
    // // console.log(stage);
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      if (countryCode === "IN") {
        if (role === "03") {
          navigate(`/india/Financier/business-details/${appId}`);
        } else if (
          constType === "partnership" ||
          constType === "proprietorship"
        ) {
          navigate(`/india/non-corporate/business-details/${appId}`);
        } else {
          navigate(`/india/corporate/business-details/${appId}`);
        }
      } else {
        if (role === "03") {
          navigate(`/foreign/Financier/business-details/${appId}`);
        } else {
          // // console.warn("here");
          navigate(`/foreign/business-details/${appId}`);
        }
      }
    } else if (stage === "In Form Signing") {
      // // console.warn("here2");
      navigate(`/application-signing/${appId}`);
    } else {
      handleModalOpen();
      setShowSpinner(false);
    }
  };

  const [checkIconClicked, setCheckIconClicked] = useState(false);
  const [emailFieldDisabled, setEmailFieldDisabled] = useState(false);
  const [checkIconDisabled, setCheckIconDisabled] = useState(false);
  //   const [showOTPField, setShowOTPField] = useState(false);
  //   const [timer, setTimer] = useState(59);

  const [clickCount, setClickCount] = useState(0);
  const [cooldownTimer, setCooldownTimer] = useState(0);

  const handleArnCheckIconClick = async () => {
    setShowValidARNAlert(false);
    if (!ARNNo) {
      // alert("Please enter ARN to continue");
      setShowValidARNAlert(true);
    } else {
      setShowChkSpinner(true);
      setClickCount((prevCount) => prevCount + 1);
      if (clickCount < 4) {
        try {
          const result = await dispatch(sendAppAccessOTP(ARNNo));
          if (result.payload) {
            // console.log(result.payload, "payload");
            // // console.warn("main file", result.payload.data.result);
            const email_to = result.payload.data.result.email_to;
            if (result.payload.data.result.error) {
              alert(result.payload.data.result.message);
              setShowChkSpinner(false);
              //  setShowOTPField(false);
            } else {
              localStorage.setItem("email_to", email_to);
              setArnCheckIconClicked(true);
              setCheckIconClicked(true);
              setEmailFieldDisabled(true);
              // Disable other relevant fields if needed
              setShowARNOtpField(true);
              setCheckIconClicked(true);
              setShowChkSpinner(false);
              setArnTimer(59); // Reset timer if needed
              setCheckIconDisabled(true);
              setOTPfromResult(result.payload?.data?.result?.otp);
              setShowOTP(true);

              setTimeout(() => {
                setCheckIconClicked(false);
                setEmailFieldDisabled(false);
                setShowARNOtpField(false);
                setCheckIconDisabled(false);
                setShowOTP(false);
              }, 60000);
            }
          }
        } catch (error) {
          setCheckIconClicked(false);
          setEmailFieldDisabled(false);
          setShowARNOtpField(false);
          setCheckIconDisabled(false);
          // // console.warn("main error-->", error);
          alert("Something went wrong! Please contact admin");
        }
      }
    }
  };

  useEffect(() => {
    if (clickCount > 4) {
      // setError("guest_user_otp", {
      //   type: "manual",
      //   message: `Maximum attempts reached. Try again after ${formatTime(
      //     cooldownTimer
      //   )}`,
      // });
      setShowChkSpinner(false);
      setCooldownTimer(180); // Reset the cooldown timer for the next attempt
    }
  }, [clickCount]);

  useEffect(() => {
    let interval: any;

    if (cooldownTimer > 0) {
      interval = setInterval(() => {
        setCooldownTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (cooldownTimer === 0) {
      setClickCount(0); // Reset the click count for the next attempt
    }

    return () => {
      clearInterval(interval);
    };
  }, [cooldownTimer]);

  const formatTime = (timeInSeconds: any) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    let arnInterval: NodeJS.Timeout | undefined;

    if (arnTimer > 0 && showARNOtpField) {
      arnInterval = setInterval(() => {
        //  // console.log("Interval executed");
        setArnTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      if (arnInterval) clearInterval(arnInterval);
    };
  }, [arnTimer, showARNOtpField]);

  return (
    <>
      <DisplayBox display={showValidARNAlert}>
        <AlertPopup message="Please enter ARN to continue" variant="warning" />
      </DisplayBox>
      <Row>
        <Col md={10}>
          <SectionHeading text="RESUME APPLICATION ACCESS" />
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <Input
            label="ARN No"
            name="guest_user_arn"
            register={register}
            required
            requiredMessage="ARN is required"
            error={errors.guest_user_arn?.message}
            // regexPattern={/^[a-zA-Z0-9]+$/}
            // regexMessage="Enter correct ARN"
            disabled={emailFieldDisabled || clickCount > 4}
            placeholder="Enter Application Reference Number"
            onChange={(e) => setARNNo(e.target.value)}
          />
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            alignItems: "start",
            marginTop: "10px",
            marginLeft: "-40px",
          }}
        >
          {showChkSpinner ? (
            <Spinner animation="border" variant="primary" size="sm" />
          ) : (
            <MdCheck
              style={{
                fontSize: "22px",
                cursor: "pointer",
                border: checkIconClicked ? "2px solid green" : "#214382",
                borderRadius: "50%",
                backgroundColor: checkIconClicked ? "green" : "#214382",
                color: checkIconClicked ? "black" : "white",
                pointerEvents:
                  checkIconDisabled || clickCount > 4 ? "none" : "auto",
              }}
              onClick={handleArnCheckIconClick}
              title="Get the OTP on Email"
            />
          )}

          <MdEdit
            onClick={handleEditIconClick}
            style={{
              fontSize: "20px",
              marginLeft: "15px",
              cursor: "pointer",
            }}
            title="Edit ARN"
          />
        </Col>
      </Row>
      {showOTP && (
        <Row>
          <Col md={10}>
            <div
              className="captcha-text"
              style={{ pointerEvents: "none", userSelect: "none" }}
            >
              {OTPfromResult}
            </div>
          </Col>
        </Row>
      )}
      {
        <Row>
          <Col md={10}>
            {/* Add offset to align with other fields */}
            <Input
              label="OTP"
              name="guest_user_otp"
              register={register}
              required
              regexPattern={/^\d{6}$/}
              maxLength={6}
              requiredMessage="OTP is required"
              error={errors.guest_user_otp?.message}
              placeholder="Enter OTP shown above"
              onChange={(e) => setOTPValue(e.target.value)}
              disabled={clickCount > 4}
            />
          </Col>
        </Row>
      }
      {clickCount > 4 && (
        <>
          <Row>
            <Col
              className="text-center"
              style={{
                color: "red",
              }}
            >
              {`Maximum OTP attempts reached. Try again after ${formatTime(
                cooldownTimer
              )}`}
            </Col>
          </Row>
        </>
      )}
      {showARNOtpField && arnTimer > 0 && (
        <Row>
          <Col md={10} className="text-end">
            <p
              style={{
                fontSize: "14px",
                margin: "0",
                padding: "0",
              }}
            >
              OTP is sent on your email. Resend OTP in{" "}
              {arnTimer < 10 ? `00:0${arnTimer}` : `00:${arnTimer}`} mins.
            </p>
          </Col>
        </Row>
      )}
      <Row className="text-end m-3">
        <Col md={10} className="text-end">
          {showSpinner ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <Button
              as="input"
              type="submit"
              value="Submit"
              className="rounded-pill"
              style={{
                backgroundColor: "#214382",
                borderColor: "#214382",
              }}
              onClick={handleSubmit(onSubmitApplicationAccess)}
            />
          )}
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Application Access Restricted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your application is currently in Verification/ Authorisation stage and
          the VTX Operations team is currently working on it. Hence, you cannot
          access your application at the moment. Thank You!
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="primary"
            label="Close"
            onClick={handleModalClose}
          ></Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ApplicationAccess;
