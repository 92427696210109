import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Button from "../../../components/common/Button";
import { Col, Container, Row, Spinner, Image, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getAppAction } from "../../opsUser/ppApplication/ppIndia/redux/action/getAppAction";
import { useParams } from "react-router-dom";
import { getSessionId, getUserRole } from "../../../utils/tokenHandlers";
import axios from "axios";
import { error } from "console";
import SectionHeading from "../../../components/common/SectionHeading";
import RadioButton from "../../../components/common/RadioButton";
import { otherDocsType } from "../redux/types/VerificationDetailsType";
import Input from "../../../components/common/Input";
import { updateAppAction } from "../../opsUser/ppApplication/ppIndia/redux/action/updateAppAction";
import { BusinessDetailsType } from "../../opsUser/ppApplication/ppIndia/pages/types/BusinessDetailsType";
import { DisplayBox } from "../../../components/containers/DisplayBox";
import AlertPopup from "../../../components/common/Alert/AlertPopup";

const OtherDocsVerification: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  let { appId } = useParams();
  let customerId = Number(appId);
  const userRole = getUserRole();
  const sessionId = getSessionId();
  const [imageData, setImageData] = useState("");
  const [fileType, setFileType] = useState("");
  const [titleText, setTitleText] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [MAClicked, setMAClicked] = useState(false);
  const [SAClicked, setSAClicked] = useState(false);
  const [makerVerStatus, setMakerVerStatus] = useState("");
  const [checkerVerStatus, setCheckerVerStatus] = useState("");
  const [showSaveAlert, setShowSaveAlert] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<BusinessDetailsType>({
    shouldUnregister: true,
  });

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );
  const appStage = getAppSelector.data.stage;
  const isCounterParty = getAppSelector.data.is_counterparty;
  const handleMasterAgreementClick = async () => {
    setMAClicked(true);
    setSAClicked(false);
    const docId = getAppSelector.data.master_agreeement_attachment_ids[0];
    // console.log("id::", docId);
    const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}?o_ses_id=${sessionId}`;
    try {
      setTitleText("Master Agreement :");
      setShowSpinner(true);
      const response = await axios.get<ArrayBuffer>(apiUrl, {
        responseType: "arraybuffer",
      });

      const dataAsArray = new Uint8Array(response.data);

      if (
        dataAsArray[0] === 0x25 &&
        dataAsArray[1] === 0x50 &&
        dataAsArray[2] === 0x44 &&
        dataAsArray[3] === 0x46
      ) {
        // const pdfBase64 = `data:application/pdf;base64,${btoa(
        //   String.fromCharCode.apply(null, Array.from(dataAsArray))
        // )}`;
        setImageData(apiUrl);
        // console.warn("its a pdf");
        setFileType("pdf");
      } else {
        const imageBase64 = `data:image/png;base64,${btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
        setImageData(imageBase64);
        // console.warn("its an img");
        setFileType("img");
      }
    } catch (error) {
      // console.error("Error fetching file:", error);
      const errors = error;
      setTitleText(
        `Error fetching file: Please make sure the Master Agreement is Uploaded!`
      );
      setImageData("");
    } finally {
      setShowSpinner(false);
    }
    // console.log("API URL:", apiUrl);
  };

  // Similarly, update handleSignedAppClick
  const handleSignedAppClick = async () => {
    setSAClicked(true);
    setMAClicked(false);
    const docId = getAppSelector.data.signed_application_form_attachment_ids[0];
    // console.log("id::", docId);
    const apiUrl = `${process.env.REACT_APP_BASE_URL}web/content/${docId}/?o_ses_id=${sessionId}`;
    try {
      setTitleText("Signed Application :");
      setShowSpinner(true);
      const response = await axios.get<ArrayBuffer>(apiUrl, {
        responseType: "arraybuffer",
      });

      const dataAsArray = new Uint8Array(response.data);

      if (
        dataAsArray[0] === 0x25 &&
        dataAsArray[1] === 0x50 &&
        dataAsArray[2] === 0x44 &&
        dataAsArray[3] === 0x46
      ) {
        // const pdfBase64 = `data:application/pdf;base64,${btoa(
        //   String.fromCharCode.apply(null, Array.from(dataAsArray))
        // )}`;
        setImageData(apiUrl);
        // console.warn("its a pdf");
        setFileType("pdf");
      } else {
        const imageBase64 = `data:image/png;base64,${btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
        setImageData(imageBase64);
        // console.warn("its an img");
        setFileType("img");
      }
    } catch (error) {
      // console.error("Error fetching file:", error);
      const errors = error;
      setTitleText(
        `Error fetching file: Please make sure the Signed Application is Uploaded!`
      );
      setImageData("");
    } finally {
      setShowSpinner(false);
    }
    // console.log("API URL:", apiUrl);
  };

  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, []);

  useEffect(() => {
    reset({
      mk_signed_application_form_attachment_status:
        getAppSelector.data.mk_signed_application_form_attachment_status,
      mk_signed_application_form_attachment_comments:
        getAppSelector.data.mk_signed_application_form_attachment_comments,
      ck_signed_application_form_attachment_status:
        getAppSelector.data.ck_signed_application_form_attachment_status,
      ck_signed_application_form_attachment_comments:
        getAppSelector.data.ck_signed_application_form_attachment_comments,
      mk_master_agreeement_attachment_status:
        getAppSelector.data.mk_master_agreeement_attachment_status,
      mk_master_agreeement_attachment_comments:
        getAppSelector.data.mk_master_agreeement_attachment_comments,
      ck_master_agreeement_attachment_status:
        getAppSelector.data.ck_master_agreeement_attachment_status,
      ck_master_agreeement_attachment_comments:
        getAppSelector.data.ck_master_agreeement_attachment_comments,
    });
  }, [getAppSelector, reset]);

  const handleOtherDocsSubmit: SubmitHandler<BusinessDetailsType> = async (
    formData
  ) => {
    // console.log(formData);
    setSubmitSpinner(true);
    setShowSaveAlert(false);
    let updateCustomerData = formData;
    dispatch(updateAppAction({ customerId, updateCustomerData })).then(
      (resp) => {
        // console.log(resp);
        setSubmitSpinner(false);
        setShowSaveAlert(true);
      }
    );
  };

  return (
    <>
      <DisplayBox display={showSaveAlert}>
        <AlertPopup
          message={"Details Saved Successfully!"}
          variant={"success"}
        />
      </DisplayBox>
      <Row className="text-center">
        <Col>
          <Button
            label="View Signed Application"
            variant="outline-primary"
            onClick={handleSignedAppClick}
          />
        </Col>
        <Col>
          {isCounterParty === false && (
            <Button
              label="View Master Agreement"
              variant="outline-primary"
              onClick={handleMasterAgreementClick}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Row className="m-3">{titleText}</Row>
        <Col md={6}>
          <Container>
            {showSpinner ? (
              <Spinner variant="primary" animation="border" />
            ) : fileType === "pdf" ? (
              <iframe
                src={imageData}
                title="Document viewer"
                style={{ width: "550px", height: "550px" }}
              />
            ) : fileType === "img" ? (
              <Image
                src={imageData}
                style={{ width: "550px", border: "solid" }}
              />
            ) : null}
          </Container>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col md="12">
            {titleText && (
              <SectionHeading text={`Document Verification:  ${titleText}`} />
            )}
          </Col>
        </Row>

        <Form onSubmit={handleSubmit(handleOtherDocsSubmit)}>
          {MAClicked && (
            <>
              <Row>
                <RadioButton
                  name={"mk_master_agreeement_attachment_status"}
                  label={"Maker Verification Status"}
                  options={[
                    { label: "Success", value: "Success" },
                    { label: "Failure", value: "Failure" },
                  ]}
                  register={register}
                  error={errors.mk_master_agreeement_attachment_status?.message}
                  required={userRole == "maker"}
                  requiredMessage="Verification Status is required"
                  disabled={userRole !== "maker"}
                  onChange={(e) => setMakerVerStatus(e.target.value)}
                />
              </Row>
              <Row>
                <Input
                  label="Maker Verification Remarks"
                  name="mk_master_agreeement_attachment_comments"
                  register={register}
                  required={userRole == "maker" && makerVerStatus == "Failure"}
                  requiredMessage="Verification Remark is required"
                  error={
                    errors.mk_master_agreeement_attachment_comments?.message
                  }
                  disabled={userRole !== "maker"}
                  placeholder="Enter maker verification remarks"
                />
              </Row>
              <Row>
                <RadioButton
                  name={"ck_master_agreeement_attachment_status"}
                  label={"Checker Verification Status"}
                  options={[
                    { label: "Success", value: "Success" },
                    { label: "Failure", value: "Failure" },
                  ]}
                  register={register}
                  error={errors.mk_master_agreeement_attachment_status?.message}
                  required={userRole !== "maker"}
                  requiredMessage="Verification Status is required"
                  disabled={userRole === "maker"}
                  onChange={(e) => setCheckerVerStatus(e.target.value)}
                />
              </Row>
              <Row>
                <Input
                  label="Checker Verification Remarks"
                  name="ck_master_agreeement_attachment_comments"
                  register={register}
                  required={
                    userRole !== "maker" && checkerVerStatus == "Failure"
                  }
                  // requiredMessage="Verification Remark is required"
                  error={
                    errors.ck_master_agreeement_attachment_comments?.message
                  }
                  disabled={userRole === "maker"}
                  placeholder="Enter checker verification remarks"
                />
              </Row>
            </>
          )}{" "}
          {SAClicked && (
            <>
              <Row>
                <RadioButton
                  name={"mk_signed_application_form_attachment_status"}
                  label={"Maker Verification Status"}
                  options={[
                    { label: "Success", value: "Success" },
                    { label: "Failure", value: "Failure" },
                  ]}
                  required={userRole === "maker"}
                  requiredMessage="Verification status required"
                  register={register}
                  error={
                    errors.mk_signed_application_form_attachment_status?.message
                  }
                  disabled={userRole !== "maker"}
                  onChange={(e) => setMakerVerStatus(e.target.value)}
                />
              </Row>
              <Row>
                <Input
                  label="Maker Verification Remarks"
                  name="mk_signed_application_form_attachment_comments"
                  register={register}
                  disabled={userRole !== "maker"}
                  placeholder="Enter maker verification remarks"
                  required={userRole == "maker" && makerVerStatus == "Failure"}
                  requiredMessage="Verification Remark is required"
                  error={
                    errors.mk_signed_application_form_attachment_comments
                      ?.message
                  }
                />
              </Row>
              <Row>
                <RadioButton
                  name={"ck_signed_application_form_attachment_status"}
                  label={"Checker Verification Status"}
                  options={[
                    { label: "Success", value: "Success" },
                    { label: "Failure", value: "Failure" },
                  ]}
                  register={register}
                  error={
                    errors.ck_signed_application_form_attachment_status?.message
                  }
                  required={userRole !== "maker"}
                  requiredMessage="Verification Status is required"
                  disabled={userRole === "maker"}
                  onChange={(e) => setCheckerVerStatus(e.target.value)}
                />
              </Row>
              <Row>
                <Input
                  label="Checker Verification Remarks"
                  name="ck_signed_application_form_attachment_comments"
                  register={register}
                  disabled={userRole !== "checker"}
                  placeholder="Enter checker verification remarks"
                  required={
                    userRole !== "maker" && checkerVerStatus == "Failure"
                  }
                  error={
                    errors.ck_signed_application_form_attachment_comments
                      ?.message
                  }
                />
              </Row>
            </>
          )}
          <Row className="mt-2">
            <Col className="text-end">
              {titleText &&
                (submitSpinner ? (
                  <Spinner variant="primary" animation="border" />
                ) : (
                  <Button
                    type="submit"
                    label="Save Details"
                    disabled={
                      appStage === "Expired" ||
                      appStage === "Customer Registered" ||
                      appStage === "In Customer Registration"
                    }
                  />
                ))}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default OtherDocsVerification;
