import axios from "axios";

export const getKeyPerson = (keyPersonId: number, sessionId: string | null) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/one/tradexwbench.customer_key_person/${keyPersonId}`,
      {
        params: {
          filter: `[]`,
          query:
            "{customer_id,key_person_type_ids,designation,if_others,partnership_percentage,first_name,middle_name,last_name,related_person_type,father_spouse_name,mother_name,gender,date_of_birth,ownership_percentage,tax_id,employee_id,passport_number,passport_expiry_date,nationality_country_id,tax_residency_country_id,email,ckycno,is_politically_exposed,is_minor,is_beneficial_owner,is_admin,guardian_relation,guardian_name,key_person_address_line_1,key_person_address_line_2,key_person_address_city,key_person_address_district,key_person_address_state,key_person_address_pincode,key_person_address_country_id,phone_country_code,phone,din,customer_key_person_document_ids, if_others }",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
