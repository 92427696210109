import React, { useEffect, useState } from "react";
import SectionHeading from "../../../../../components/common/SectionHeading";
import { Container, Row, Col, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import Dropdown from "../../../../../components/common/dropdowns/Dropdown";
import Input from "../../../../../components/common/Input";
import DatePicker from "../../../../../components/common/DatePicker";
import ListingInfoDropdown from "../../../../../components/common/ListingInfoDropdown";
import RadioButton from "../../../../../components/common/RadioButton";
import Button from "../../../../../components/common/Button";
import RegisterWith from "../../../../../components/common/dropdowns/RegisterWith";
import NatureOfBusiness from "../../../../../components/common/dropdowns/NatureOfBusiness";
import FinancierTypeIndia from "../../../../../components/common/dropdowns/FinancierTypeIndia";
import { BusinessDetailsType } from "../pages/types/BusinessDetailsType";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { updateAppAction } from "../redux/action/updateAppAction";
import { DisplayBox } from "../../../../../components/containers/DisplayBox";
import AlertPopup from "../../../../../components/common/Alert/AlertPopup";
import { getAppAction } from "../redux/action/getAppAction";
import {
  ALPHABETS_REGEX,
  ALPHANUMERIC_REGEX,
  LEI_REGEX,
  WEBSITE_REGEX,
} from "../../../../constants/regexPatterns";
import CPBusinessDetailsFields from "./CPBusinessDetailsFields";
import { getAddressFromCinAction } from "../redux/action/getAddressFromCinAction";
import { parseAddress } from "../../../../../utils/functions/parseAddress";
// import { getRegisteredAddress } from "../apis/getRegisteredAddress";
import { getAllAddressAction } from "../redux/action/getAllAddressAction";
import { updateRegAddrCIN } from "../redux/action/updateRegAddrCIN";
import { getRegisteredAddress } from "../../../../verificationStage/redux/action/getRegisteredAddress";
import SendBackPdfBtn from "../../../../../components/common/sendBackPdfBtn";
import BusinessUnitLocation from "../../../../../components/common/dropdowns/BusinessUnitLocation";
import { convertDateFormat } from "../../../../../utils/functions/convertDateFormat";
import { createAddressAction } from "../redux/action/createAddressAction";
const IFBusinessDetails: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { appId } = useParams();
  const customerId = Number(appId);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<BusinessDetailsType>({
    shouldUnregister: true,
  });

  const allAddressSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getAllAddress.data
  );

  const state = useSelector((state: RootState) => state.opsPpIndApp.getApp);
  let stage = state.data.stage;

  const [selectedFinancierTypeValue, setSelectedFinancierTypeValue] = useState<
    string | undefined
  >(undefined);

  const [defaultSelectedListing, setDefaultSelectedListing] = useState<
    number[]
  >([]);
  const cinValue = watch("business_cin_no");
  const financierType = watch("business_financier_type");
  const [allowListingSelection, setAllowListingSelection] = useState(true);
  useEffect(() => {
    if (cinValue) {
      const firstCharacter = cinValue.charAt(0);
      if (firstCharacter === "U") {
        // console.warn("CIN starts with U");
        setAllowListingSelection(false);
      } else if (firstCharacter === "L") {
        // console.warn("CIN starts with L");
        setAllowListingSelection(true);
      }
    }
  }, [cinValue]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  ////const [selectedValues, setSelectedValues] = useState<number[]>([]);

  const handleSelectedValuesChange = (newSelectedValues: number[]) => {
    setSelectedValues(newSelectedValues);
  };

  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    dispatch(getAppAction(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    // console.log("App Data ----> ", state);

    reset({
      business_registered_with: state.data.business_registered_with,
      business_registration_place: state.data.business_registration_place,
      business_listing_info_ids: state.data.business_listing_info_ids,
      business_cin_no: state.data.business_cin_no,
      business_registration_date: state.data.business_registration_date,
      business_commencement_date: state.data.business_commencement_date,
      business_legal_entity_identifier:
        state.data.business_legal_entity_identifier,
      business_website: state.data.business_website,
      business_nature: state.data.business_nature,
      business_iecno: state.data.business_iecno,
      business_nature_activity: state.data.business_nature_activity,
      business_unit_location: state.data.business_unit_location,
      business_statusholder: state.data.business_statusholder,
      business_ckycno: state.data.business_ckycno,
      business_registration_number: state.data.business_registration_number,
      is_msme: state.data.is_msme === true ? "true" : "false",
      is_two_factor: state.data.is_two_factor,
      business_regulated_by: state.data.business_regulated_by,
      business_financier_type: state.data.business_financier_type,
      business_registration_certificate_no:
        state.data.business_registration_certificate_no,
      business_issued_date: state.data.business_issued_date,
      business_no_of_years_of_relation_with_cp:
        state.data.business_no_of_years_of_relation_with_cp,
      referred_by_vendor_dealer_id: state.data.referred_by_vendor_dealer_id,
      business_is_cp_related_party: state.data.business_is_cp_related_party,
    });
    setDefaultSelectedListing(state.data.business_listing_info_ids);
  }, [state, reset]);

  useEffect(() => {
    reset({ business_registered_with: "MCA", business_nature: "service" });
  }, [reset]);

  // const handleCinInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newValue = e.target.value;
  //   setInputValue(newValue);

  //   // Check if manually typed characters hit 21 and trigger the useEffect
  //   if (newValue.length === 21) {
  //     // console.log("cin value got");
  //     dispatch(
  //       getAddressFromCinAction({
  //         cin: cinValue,
  //         customerId: customerId,
  //       })
  //     ).then((addressResponse) => {
  //       try {
  //         if (addressResponse.payload) {
  //           // console.log(addressResponse.payload, "---resp");
  //           let address =
  //             addressResponse.payload.result.result.Registered_Address;
  //           let parseAddressDetails = parseAddress(address);
  //           let addressDetailsData = {
  //             customer_id: appId,
  //             type: "Registered",
  //             ...parseAddressDetails,
  //           };
  //           // console.warn("parsed addr-->", addressDetailsData);
  //           // dispatch(createAddressAction(addressDetailsData)).then((res) => {
  //           //   dispatch(
  //           //     updateAppAction({
  //           //       customerId: customerId,
  //           //       updateCustomerData: {
  //           //         business_registration_place:
  //           //           addressResponse.payload.result.result.ROC_Code,
  //           //         business_registration_date: convertDateFormat(
  //           //           addressResponse.payload.result.result.Date_of_Incorporation
  //           //         ),
  //           //       },
  //           //     })
  //           //   ).then((response: any) => {
  //           //     // console.log(response, "address updated");
  //           //     if (response.payload.result === true) {
  //           //       // console.warn("true");

  //           //     }
  //           //   });
  //           // });
  //           dispatch(getRegisteredAddress(customerId)).then((resp: any) => {
  //             // console.warn(resp.payload);
  //             if (resp.payload.data.result.count === 1) {
  //               // console.warn(resp.payload.data.result.count);
  //               const addressId = resp.payload.data.result.result[0].id;
  //               // console.warn(addressId);
  //               dispatch(
  //                 updateRegAddrCIN({
  //                   addressId,
  //                   addressDetails: addressDetailsData,
  //                 })
  //               ).then((response: any) => {
  //                 // console.log("resp--->", response);
  //                 dispatch(getAllAddressAction(customerId));
  //               });
  //             }
  //           });
  //         }
  //       } catch (err) {
  //         // console.log("something went wrong", err);
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (!allowListingSelection) {
      if (state.data.business_listing_info_ids) {
        setValue("business_listing_info_ids", [264]);
      }
    }
  });
  const handleCinInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    // console.log(newValue);

    // Check if manually typed characters hit 21 and trigger the useEffect
    if (newValue.length === 21) {
      // console.log("cin value got");
      dispatch(
        getAddressFromCinAction({
          cin: newValue,
          customerId: customerId,
        })
      ).then((addressResponse) => {
        try {
          if (addressResponse.payload) {
            // console.log(addressResponse.payload, "---resp");
            let address =
              addressResponse.payload.result.result.Registered_Address;
            let parseAddressDetails = parseAddress(address);
            let addressDetailsData = {
              customer_id: customerId,
              type: "Registered",
              ...parseAddressDetails,
            };
            // console.warn("parsed addr-->", addressDetailsData);
            dispatch(createAddressAction(addressDetailsData)).then((res) => {
              dispatch(
                updateAppAction({
                  customerId: customerId,
                  updateCustomerData: {
                    business_registration_place:
                      addressResponse.payload.result.result.ROC_Code,
                    business_registration_date: convertDateFormat(
                      addressResponse.payload.result.result
                        .Date_of_Incorporation
                    ),
                  },
                })
              ).then((response: any) => {
                // console.log(response, "address updated");
                if (response.payload.result === true) {
                  // console.warn("true");
                }
              });
            });
            dispatch(
              updateAppAction({
                customerId: customerId,
                updateCustomerData: {
                  is_cin_captured: true,
                  business_cin_no: newValue,
                  business_registration_place:
                    addressResponse.payload.result.result.ROC_Code,
                  business_registration_date: convertDateFormat(
                    addressResponse.payload.result.result.Date_of_Incorporation
                  ),
                },
              })
            ).then((resp) => {
              dispatch(getAllAddressAction(customerId));
              dispatch(getAppAction(customerId));
            });

            dispatch(getRegisteredAddress(customerId)).then((resp: any) => {
              // console.warn(resp.payload);
              if (resp.payload.data.result.count === 1) {
                // console.warn(resp.payload.data.result.count);
                const addressId = resp.payload.data.result.result[0].id;
                // console.warn(addressId);
                dispatch(
                  updateRegAddrCIN({
                    addressId,
                    addressDetails: addressDetailsData,
                  })
                ).then((response: any) => {
                  // console.log("resp--->", response);
                  dispatch(getAllAddressAction(customerId));
                });
              }
            });
          }
        } catch (err) {
          // console.log("something went wrong", err);
        }
      });
    }
  };
  // useEffect(() => {
  //   if (cinValue && cinValue.length === 21) {
  //     // console.log("cin value got");
  //     dispatch(
  //       getAddressFromCinAction({
  //         cin: cinValue,
  //         customerId: customerId,
  //       })
  //     ).then((addressResponse) => {
  //       try {
  //         if (addressResponse.payload) {
  //           // console.log(addressResponse.payload, "---resp");
  //           let address =
  //             addressResponse.payload.result.result.Registered_Address;
  //           let parseAddressDetails = parseAddress(address);
  //           let addressDetailsData = {
  //             customer_id: appId,
  //             type: "Registered",
  //             ...parseAddressDetails,
  //           };
  //           // console.warn("parsed addr-->", addressDetailsData);
  //           // dispatch(createAddressAction(addressDetailsData)).then((res) => {
  //           //   dispatch(
  //           //     updateAppAction({
  //           //       customerId: customerId,
  //           //       updateCustomerData: {
  //           //         business_registration_place:
  //           //           addressResponse.payload.result.result.ROC_Code,
  //           //         business_registration_date: convertDateFormat(
  //           //           addressResponse.payload.result.result.Date_of_Incorporation
  //           //         ),
  //           //       },
  //           //     })
  //           //   ).then((response: any) => {
  //           //     // console.log(response, "address updated");
  //           //     if (response.payload.result === true) {
  //           //       // console.warn("true");

  //           //     }
  //           //   });
  //           // });
  //           dispatch(getRegisteredAddress(customerId)).then((resp: any) => {
  //             // console.warn(resp.payload);
  //             if (resp.payload.data.result.count === 1) {
  //               // console.warn(resp.payload.data.result.count);
  //               const addressId = resp.payload.data.result.result[0].id;
  //               // console.warn(addressId);
  //               dispatch(
  //                 updateRegAddrCIN({
  //                   addressId,
  //                   addressDetails: addressDetailsData,
  //                 })
  //               ).then((response: any) => {
  //                 // console.log("resp--->", response);
  //                 dispatch(getAllAddressAction(customerId));
  //               });
  //             }
  //           });
  //         }
  //       } catch (err) {
  //         // console.warn("something went wrong", err);
  //       }
  //     });
  //   }
  // }, [cinValue]);

  const handleFinancierTypeDropdownChange = (value: string) => {
    setSelectedFinancierTypeValue(value);
    // console.warn(value);
  };
  const handleBusinessDetailsSubmit: SubmitHandler<
    BusinessDetailsType
  > = async (updateCustomerData) => {
    if (stage === "New / Draft" || stage === "Sent back to correction") {
      // console.log(updateCustomerData);
      setShowSuccessAlert(false);
      if (selectedValues.length === 0) {
        // If empty, set business_listing_info_ids to state.data.business_listing_info_ids
        updateCustomerData.business_listing_info_ids =
          state.data.business_listing_info_ids;
      } else {
        // If not empty, set business_listing_info_ids to selectedValues
        updateCustomerData.business_listing_info_ids = selectedValues;
      }
      updateCustomerData.business_registered_with = "MCA";
      updateCustomerData.business_nature = "service";
      if (updateCustomerData.is_msme === "true") {
        updateCustomerData.is_msme = true;
      } else {
        updateCustomerData.is_msme = false;
      }
      // console.log("Business Details", updateCustomerData);
      dispatch(updateAppAction({ customerId, updateCustomerData })).then(
        (response) => {
          if (response.payload.result) {
            setShowSuccessAlert(true);
          }
        }
      );
    } else {
      alert("Cannot edit application at this stage!");
    }
  };

  return (
    <>
      <Container>
        <Row>
          <DisplayBox display={showSuccessAlert}>
            <AlertPopup
              variant="success"
              message="Business Details Updated Successfully"
            />
          </DisplayBox>
          <Row>
            <Col md="12">
              <SectionHeading text="BUSINESS DETAILS" />
            </Col>
            <Col className="text-end">
              <SendBackPdfBtn />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(handleBusinessDetailsSubmit)}>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <RegisterWith
                  label="Business Registration"
                  name="business_registered_with"
                  register={register}
                  // required
                  // requiredMessage="Business Registration is required"
                  error={errors.business_registered_with?.message}
                  defaultValue="MCA"
                  disabled
                />
              </Col>
              <Col sm={6} className="mt-2">
                <Input
                  label="Place of Incorporation"
                  name="business_registration_place"
                  register={register}
                  required
                  // regexPattern={ALPHANUMERIC_REGEX}
                  requiredMessage="Incorporation Place is required"
                  error={errors.business_registration_place?.message}
                  placeholder="Enter place of registration"
                  //disabled
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <Input
                  label="CIN"
                  name="business_cin_no"
                  register={register}
                  required
                  requiredMessage="CIN number is required"
                  error={errors.business_cin_no?.message}
                  placeholder="Enter CIN number"
                  onChange={handleCinInputChange}
                  // disabled
                />
              </Col>
              <Col sm={6} className="mt-2">
                <DatePicker
                  label="Date of Incorporation"
                  name="business_registration_date"
                  register={register}
                  isDateOfBirth={true}
                  required
                  requiredMessage="DOI is required"
                  error={errors.business_registration_date?.message}
                  //disabled
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6} className="d-flex align-items-center">
                {allowListingSelection ? (
                  defaultSelectedListing && (
                    <ListingInfoDropdown
                      selectedValues={defaultSelectedListing}
                      onSelectedValuesChange={handleSelectedValuesChange}
                      name="business_listing_info_ids"
                      label="Listing Information"
                      required
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      //defaultValue={state.data.business_listing_info_ids}
                      error={errors.business_listing_info_ids?.message}
                      // disabled={
                      //   !(
                      //     userRole == "maker" ||
                      //     (userRole == "guest" && stage == "New / Draft")
                      //   )
                      // }
                    />
                  )
                ) : (
                  // <Input
                  //   label="business_listing_info_ids"
                  //   name="business_listing_info_ids"
                  //   register={register}
                  //   // required
                  //   // requiredMessage="Listing Information is required"
                  //   // error={errors.business_listing_info_ids?.message}
                  //   placeholder="Unlisted"
                  //   defaultValue="Unlisted"
                  //   disabled
                  // />
                  <Dropdown
                    name={"business_listing_info_ids"}
                    label={"Listing Information"}
                    options={[{ label: "Unlisted", value: [264] }]}
                    register={register}
                    disabled
                  />
                )}
              </Col>
              <Col sm={6}>
                <DatePicker
                  label="Date of Commencement"
                  name="business_commencement_date"
                  register={register}
                  isDateOfBirth={true}
                  // required
                  // requiredMessage="DOC is required"
                  // error={errors.business_commencement_date?.message}
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6}>
                <Input
                  label="Legal Entity Identifier (LEI)"
                  name="business_legal_entity_identifier"
                  register={register}
                  regexPattern={LEI_REGEX}
                  // required
                  // requiredMessage="LEI is required"
                  error={errors.business_legal_entity_identifier?.message}
                  placeholder="Enter LEI"
                  //disabled
                />
              </Col>
              <Col sm={6}>
                <Input
                  label="Website"
                  name="business_website"
                  register={register}
                  regexPattern={WEBSITE_REGEX}
                  //regexPattern={/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/}
                  regexMessage="Invalid website URL format"
                  // error={errors.business_website?.message}
                  placeholder="Enter website url"
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6} className="mt-2">
                <NatureOfBusiness
                  label="Nature of Business"
                  name="business_nature"
                  register={register}
                  //required
                  // requiredMessage="Nature of Business is required"
                  error={errors.business_nature?.message}
                  defaultValue="service"
                  disabled
                />
              </Col>
              <Col sm={6} className="mt-2">
                <Dropdown
                  label="Regulator Name"
                  name="business_regulated_by"
                  register={register}
                  options={[
                    {
                      label:
                        "International Financial Services Centers Authority",
                      value: "ifsca",
                    },
                    {
                      label: "Reserve Bank of India",
                      value: "rbi",
                    },
                  ]}
                  required
                  requiredMessage="Regulator name is required"
                  error={errors.business_regulated_by?.message}
                />
              </Col>
            </Row>
            <Row md={12} className="mt-2">
              <Col sm={6}>
                <FinancierTypeIndia
                  label="Financier Type"
                  name="business_financier_type"
                  register={register}
                  required
                  requiredMessage="Financier type is required"
                  error={errors.business_financier_type?.message}
                />

                {financierType === "others" && (
                  <Input
                    label="If others"
                    name="business_financier_type_other"
                    register={register}
                    required
                    // regexPattern={ALPHABETS_REGEX}
                    // requiredMessage="Please provide additional details"
                    placeholder="Enter Other financier type"
                    // error={errors.business_financier_type_other?.message}
                  />
                )}
              </Col>

              <Col sm={6}>
                <Input
                  label="Regulatory License No"
                  name="business_registration_certificate_no"
                  register={register}
                  placeholder="Enter Regulatory License No"
                  required
                  regexPattern={ALPHANUMERIC_REGEX}
                  requiredMessage="Regulatory License No  is required"
                  error={errors.business_registration_certificate_no?.message}
                />
              </Col>
            </Row>

            <Row md={12} className="mt-2">
              <Col sm={6}>
                <RadioButton
                  label="FCI 2-Factor"
                  name="is_two_factor"
                  register={register}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  required
                  requiredMessage="Two factor selection is required"
                  error={errors.is_two_factor?.message}
                />
              </Col>
              <Col sm={6}>
                <DatePicker
                  label="License Issue Date"
                  name="business_issued_date"
                  register={register}
                  required
                  isDateOfBirth
                  requiredMessage="Issue date is required"
                  error={errors.business_issued_date?.message}
                />
              </Col>
            </Row>
            <Row md={12} className="mt-2">
              <Col sm={6}>
                {/* <RadioButton
                  label="FCI 2-Factor"
                  name="is_two_factor"
                  register={register}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  required
                  requiredMessage="Two factor selection is required"
                  error={errors.is_two_factor?.message}
                /> */}
                <BusinessUnitLocation
                  label="Business unit location"
                  name="business_unit_location"
                  register={register}
                  required
                  defaultValue={state.data.business_unit_location}
                  error={errors.business_unit_location?.message}
                  // disabled={
                  //   !(
                  //     userRole == "maker" ||
                  //     (userRole == "guest" && stage == "New / Draft")
                  //   )
                  // }
                />
              </Col>
            </Row>
            <Row md={12}>
              <Col sm={6}>
                <DisplayBox
                  display={
                    state.data.business_participant_type === "Seller" &&
                    state.data.is_counterparty === false
                  }
                >
                  {/* <p>true</p> */}
                  <RadioButton
                    label="Is MSME ?"
                    name="is_msme"
                    register={register}
                    options={[
                      { label: "Yes", value: "true" },
                      { label: "No", value: "false" },
                    ]}
                    required
                    requiredMessage="Is MSME selection is required"
                    error={errors.is_msme?.message}
                  />
                </DisplayBox>
              </Col>
            </Row>
            {state.data.is_counterparty === true && (
              <CPBusinessDetailsFields register={register} errors={errors} />
            )}
            <Row>
              <Col className="m-2 text-end">
                <Button label="Save Details" type="submit" />
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default IFBusinessDetails;
