import axios from "axios";
import { getSessionId } from "../../../utils/tokenHandlers";

export const getIndividualDocs = async (
  customerId: number | null,
  selectedId: number | null
) => {
  const sessionid = getSessionId();
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer_key_person/`,
      {
        params: {
          filter: `[('customer_id', 'in',  [${customerId}]), ('id', '=', [${selectedId}])]`,
          query:
            "{id, customer_id, customer_key_person_document_ids{id, document_type, document_name, attachment_ids, document_name_text}}",
        },
      },
      {
        params: {
          o_ses_id: sessionid,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => err);
};
