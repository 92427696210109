import axios from "axios";
import { FilterParams } from "../pages/ApplicationListView/ApplicationListView";

export const getParticipantAPI = async (
  sessionId: string | null,
  filterParams: FilterParams
) => {
  //// console.log("get counter pARTY-------------------------------;")

  let defaultFilter: any = "('is_counterparty','!=','true')";

  let filter: any[] = [defaultFilter];

  // if (filterParams.isGuestUser) {
  //   filter = [...filter, `('is_guest_user','=', True) `];
  // } else {
  //   filter = [...filter, `('is_guest_user','=', False) `];
  // }

  if (filterParams.appStage) {
    filter = [...filter, `('stage', '=' , '${filterParams.appStage}') `];
  }

  if (filterParams.searchData) {
    filter = [
      ...filter,
      `('submitted_date', '=' ,'${filterParams.searchData}') `,
    ];
  }

  if (filterParams.fromDate) {
    filter = [
      ...filter,
      `('submitted_date', '>' ,'${filterParams.fromDate}') `,
    ];
  }

  if (filterParams.tillDate) {
    filter = [
      ...filter,
      `('submitted_date', '<' ,'${filterParams.tillDate}') `,
    ];
  }

  if (filterParams.application_uid) {
    filter = [
      ...filter,
      `('application_uid', 'ilike' ,'${filterParams.application_uid}') `,
    ];
  }

  if (filterParams.search_any) {
    let searchFilter: any[] = [];

    // Add 16 '|' symbols enclosed in single quotes
    for (let i = 0; i < 15; i++) {
      searchFilter.push(`'|'`);
    }

    // Add columns with 'ilike' condition and values
    searchFilter.push(
      "('application_uid', 'ilike', '" + filterParams.search_any + "')",
      "('business_name', 'ilike', '" + filterParams.search_any + "')",
      "('referred_by', 'ilike', '" + filterParams.search_any + "')",
      "('business_participant_type', 'ilike', '" +
        filterParams.search_any +
        "')",
      "('customer_contact_name', 'ilike', '" + filterParams.search_any + "')",
      "('customer_contact_mobile', 'ilike', '" + filterParams.search_any + "')",
      "('customer_contact_email', 'ilike', '" + filterParams.search_any + "')",
      "('business_gst', 'ilike', '" + filterParams.search_any + "')",
      "('business_constitution_type', 'ilike', '" +
        filterParams.search_any +
        "')",
      "('business_cin_no', 'ilike', '" + filterParams.search_any + "')",
      "('business_ckycno', 'ilike', '" + filterParams.search_any + "')",
      "('business_iecno', 'ilike', '" + filterParams.search_any + "')",
      "('business_legal_entity_identifier', 'ilike', '" +
        filterParams.search_any +
        "')",
      "('business_nature', 'ilike', '" + filterParams.search_any + "')",
      "('business_registration_place', 'ilike', '" +
        filterParams.search_any +
        "')",
      "('business_website', 'ilike', '" + filterParams.search_any + "')"
    );

    filter.push(searchFilter);
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/read/many/tradexwbench.customer`,
      {
        params: {
          filter: `[${filter}]`,
          all_pages: "true",
          order: "create_date desc",
          query:
            "{id,is_guest_user,create_uid{name},write_date,stage ,application_uid,is_counterparty,referred_by, business_participant_type,business_registration_country_id{name},customer_contact_name,  customer_contact_mobile_country_code,customer_contact_mobile,  customer_contact_email,employee_declaration_accepted,employee_id,employee_name{name}, submitted_date,business_gst_details_fetch_over_api,business_gst_api_results_available,business_gst,business_constitution_type,business_name, business_is_ftr_ftnr,business_cin_no,business_ckycno,business_commencement_date,business_iecno,business_legal_entity_identifier,business_listing_info_ids,business_nature,business_nature_activity,business_registered_with,business_registration_date, business_registration_place, business_statusholder,business_unit_location,business_website, create_date, participant_number_cnx, participant_response_message_cnx, participant_reason_cnx, checker_submitted_date, authorise_date }",
        },
      },
      {
        params: {
          o_ses_id: `${sessionId}`,
        },
      }
    );

    // console.log("pp here", response);
    return response;
  } catch (err) {
    return err;
  }
};
