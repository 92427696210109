import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import React from "react";
import VerBusinessDetails from "../../../components/common/VerBusinessDetails";

const VerRegisteredAddress: React.FC = () => {
  const state = useSelector(
    (state: RootState) => state.verification.verRegAddressDetails
  );

  const getAppSelector = useSelector(
    (state: RootState) => state.opsPpIndApp.getApp
  );

  if (!state.data || !state.data.data) {
    return <Row>Loading...</Row>;
  }
  const regAddressDetailsStore = state.data.data.result.result[0];
  const shippingAddrId = getAppSelector.data.business_shipping_address_type_id;
  const billingAddrId = getAppSelector.data.business_billing_address_type_id;
  const isIndia = getAppSelector.data.business_registration_country_id === 239;
  const regAddrId = regAddressDetailsStore && regAddressDetailsStore.id;
  const handleNullValue = (value: null, defaultValue: any) => {
    return value != null ? value : defaultValue;
  };
  return (
    <>
      {/* <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails label="Address Type" defaultValue="Registered" />
        </Col>
      </Row> */}
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Address Line 1"
            defaultValue={regAddressDetailsStore.line_1}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Address Line 2"
            defaultValue={regAddressDetailsStore.line_2}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="City"
            defaultValue={regAddressDetailsStore.city}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="District"
            defaultValue={regAddressDetailsStore.district}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="State/ County"
            defaultValue={regAddressDetailsStore.state}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Country"
            defaultValue={regAddressDetailsStore.country_id.name}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Email"
            defaultValue={regAddressDetailsStore.email}
          />
        </Col>
      </Row>
      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="PIN/ ZIP Code"
            defaultValue={regAddressDetailsStore.pincode}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Cellphone"
            defaultValue={
              regAddressDetailsStore.phone_country_code +
              -+regAddressDetailsStore.phone
            }
          />
        </Col>
      </Row>
      {isIndia && (
        <Row md={12}>
          <Col md={12}>
            <VerBusinessDetails
              label="Billing GSTIN"
              defaultValue={regAddressDetailsStore.business_billing_gstn}
            />
          </Col>
        </Row>
      )}
      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Billing Preference"
            defaultValue={
              regAddressDetailsStore.business_billing_preference_email
            }
          />
        </Col>
      </Row>

      <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Communication Preference"
            defaultValue={
              regAddressDetailsStore.business_communication_preference_email
            }
          />
        </Col>
      </Row>

      <Row md={12}>
        <Col md={6}>
          <VerBusinessDetails
            label="Is Shipping Address"
            defaultValue={regAddrId === shippingAddrId ? "Yes" : "No"}
          />
        </Col>
        <Col md={6}>
          <VerBusinessDetails
            label="Is Billing Address"
            defaultValue={regAddrId === billingAddrId ? "Yes" : "No"}
          />
        </Col>
      </Row>
      {/* <Row md={12}>
        <Col md={12}>
          <VerBusinessDetails
            label="Billing GSTIN"
            defaultValue={regAddressDetailsStore.billing_gstn}
          />
        </Col>
      </Row> */}
    </>
  );
};

export default VerRegisteredAddress;
